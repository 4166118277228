<template>
  <div>
    <div class="mb-6">
      <h3 class="cmn__h1">Аналитика за период</h3>
      <v-row>
        <v-col>
          <v-text-field
            label="Начало отчетного периода"
            v-model="analiticsParams.startDate"
            :rules="validation.date"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Конец отчетного периода"
            v-model="analiticsParams.endDate"
            :rules="validation.date"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-btn @click="analiticsShow" class="ml-3">Показать</v-btn>
          <v-btn @click="analiticsCsv" class="ml-3">Скачать в CSV</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <DataTable v-if="analiticsTableShow"
            :key="analiticsCommonFiltersHash"
            :headers="analiticsCommonHeaders"
            :items="analiticsCommonItems"
            :loading="analiticsCommonUpdating"
            :hide-default-footer="true"
            @update:table-options="analiticsCommonTableUpdated"
          >
            <template v-slot:[`item.count`]="{item}">
              {{ item.count }}
              <span class="count-added" v-if="item.countAdded">({{ item.countAdded > 0 ? `+${item.countAdded}` : item.countAdded }})</span>
            </template>
          </DataTable>
        </v-col>
        <v-col>
          <DataTable v-if="analiticsTableShow"
            :key="analiticsFiltersHash"
            :headers="analiticsHeaders"
            :items="analiticsItems"
            :server-items-length="analiticsTotal"
            :loading="analiticsUpdating"
            @update:table-options="analiticsTableUpdated"
          >
            <template v-slot:[`item.count`]="{item}">
              {{ item.count }}
              <span class="count-added">({{ item.countAdded > 0 ? `+${item.countAdded}` : item.countAdded }})</span>
            </template>
          </DataTable>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import scountApiInitializer from '@/api/scountApi';
// import ratingsApiInitializer from '@/api/ratingsApi';
import Moment from 'moment'

export default {
  name: 'AnaliticsView',

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    validation: {
      date: [
        value => !value || /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d$/i.test(value) || 'Некорректная дата. Формат ДД.ММ.ГГГГ',
      ],
    },
    reportId: null,
    analiticsCommonHeaders: [
      { value: 'name', text: 'Общая статистика', align: 'start', sortable: false },
      { value: 'count', text: '', align: 'start', sortable: false },
    ],
    analiticsCommonUpdating: false,
    analiticsCommonItems: undefined,
    analiticsCommonFiltersHash: Date.now(),

    analiticsHeaders: [
      { value: 'nominationName', text: 'Статистика по наминациям', align: 'start', sortable: false },
      { value: 'count', text: 'Всего участников', align: 'start', sortable: false },
    ],
    analiticsTotal: 0,
    analiticsUpdating: false,
    analiticsParams: {
      startDate: undefined,
      endDate: undefined,
    },
    analiticsItems: undefined,
    analiticsFiltersHash: Date.now(),
    analiticsTableShow: false,
  }),

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },

    // ratingsApi() {
    //   if (!this.mp)
    //     return null;

    //   return ratingsApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    // },
  },

  methods: {
    async analiticsShow() {
      this.analiticsTableShow = true;
      this.analiticsFiltersHash = Date.now();
      this.analiticsCommonFiltersHash = Date.now();
    },

    async analiticsCommonTableUpdated(pagination, sorting, search) {
      if (!this.scountApi) return;

      this.analiticsCommonUpdating = true;

      try { 
        const response = await this.scountApi.getAnalitics(
          pagination,
          sorting,
          search,
          this.analiticsParams.startDate ? Moment(this.analiticsParams.startDate, 'DD.MM.YYYY').toDate() : null,
          this.analiticsParams.endDate ? Moment(this.analiticsParams.endDate, 'DD.MM.YYYY').toDate() : null,
        );

        const d = response.data.common;

        this.analiticsCommonItems = [
          { name: 'Начисленные баллы', count: d.gainedPoints.count },
          { name: 'Остаток по баллам', count: d.pointBalance.count, countAdded: d.pointBalance.countAdded },
          { name: 'Сколько человек подключили трекер', count: d.countTrackerConnected.count },
          { name: 'Сколько человек тренируется (фоновые+задания)', count: d.countTrainingsParticipants.count },
          { name: 'Сколько человек берут только задания', count: d.countOnlyExercises.count },
          { name: 'Количество женщин-участников', count: d.countFemale.count, countAdded: d.countFemale.countAdded },
          { name: 'Количество мужчин-участников', count: d.countMale.count, countAdded: d.countMale.countAdded },
        ];
      } finally {
        this.analiticsCommonUpdating = false;
      }
    },

    async analiticsTableUpdated(pagination, sorting, search) {
      await this.getAnalitics(pagination, sorting, search);
    },

    async getAnalitics() {
      if (!this.scountApi) return;

      this.analiticsUpdating = true;

      try {
        await this.scountApi.generateAnalitics(
          this.analiticsParams.startDate ? Moment(this.analiticsParams.startDate, 'DD.MM.YYYY').toDate() : null,
          this.analiticsParams.endDate ? Moment(this.analiticsParams.endDate, 'DD.MM.YYYY').toDate() : null,
        ).then(response => {
          if (response.status === 200) {
            this.reportId = response.data;
            // this.checkReportStatus();
          }
        });
      } finally {
        this.analiticsUpdating = false;
      }
    },

    async analiticsCsv() {
      this.analiticsCsvPart();
    },

    async analiticsCsvPart() {
      const response = await this.scountApi.getAnaliticsCsv(
        this.analiticsParams.startDate ? Moment(this.analiticsParams.startDate, 'DD.MM.YYYY').toDate() : null,
        this.analiticsParams.endDate ? Moment(this.analiticsParams.endDate, 'DD.MM.YYYY').toDate() : null
      );

      const fileUrl = window.URL.createObjectURL(response.data);

      const anchor = document.createElement("a");
      anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
      anchor.href = fileUrl;
      anchor.target = "_blank";
      anchor.click();

      anchor.remove();
    },
  },
}
</script>