<template>
  <div>
    <div class="mb-6">
      <h2 class="cmn__h1 mb-5">Аналитика тестам/опросам</h2>
      <v-form ref="form" lazy-validation v-model="valid">
        <v-row>
          <!-- <v-col>
            <v-autocomplete
              label="МП"
              :items="marketingPrograms"
              item-text="title"
              item-value="marketingProgramId"
              v-model="marketingProgramId"
              no-data-text="Ничего не найдено"
            />
          </v-col> -->
          <v-col>
            <v-autocomplete
              label="Тест/Опрос"
              :items="quizes"
              item-text="exerciseTasksName"
              item-value="quizId"
              v-model="surveyId"
              no-data-text="Ничего не найдено"
              :rules="validation.surveyOption"
            />
          </v-col>
          <v-col>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dateRange"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Дата участия"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearDateRange"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateRange"
                range
                label="Сроки проведения МП (необязательно)"
                locale="ru"
                :first-day-of-week="1"
              >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Отмена
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(dateRange)"
              >
                OK
              </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="d-flex align-end">
            <v-btn large @click="surveyShow" :disabled="!valid" class="ml-3">Показать</v-btn>
            <v-btn large @click="getSurveyCsv" :disabled="!valid" class="ml-3">
              <v-icon left>
                  mdi-content-save
              </v-icon>
              скачать с ответами в CSV
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <DataTable
        v-if="surveyTableShow"
        :key="activeCoreFiltersHash"
        :headers="surveyHeaders"
        :server-items-length="surveyTotal"
        :items="surveyItems || []"
        :loading="surveyUpdating"
        @update:table-options="surveyTableUpdated"
      >
      <template v-slot:[`item.completedOn`]="{item}">
        {{ item.completedOn ? formatDate(item.completedOn) : '-' }}
      </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import { useKeycloakStore } from '@/stores/keycloakStore';
import quizesApiInitializer from '@/api/quizesApi';
import Moment from 'moment';
  export default {
    name: 'SurveyAnalyticsView',
    data() {
      return {
        marketingProgramId: null,
        surveyId: null,
        surveyItems: [],
        dateRange: [],
        menu: false,
        surveyHeaders: [
          { text: 'ID Участника', value: 'participantId', sortable: false },
          { text: 'Фамилия', value: 'firstName', sortable: false },
          { text: 'Имя', value: 'lastName', sortable: false },
          { text: 'Email', value: 'emailAddress', sortable: false },
          { text: 'Время участия', value: 'completedOn', sortable: false },
        ],
        surveyUpdating: false,
        activeCoreFiltersHash: Date.now(),
        surveyTableShow: false,
        quizes: [],
        surveyTotal: 0,
        surveyIdError: false,
        valid: true,
        validation: {
          surveyOption: [
            value => !!value || 'Обязательное поле',]
        },
      }
    },

    setup() {
      const marketingProgramStore = useMarketingProgramStore()
      const keycloakStore = useKeycloakStore()
      return { marketingProgramStore, keycloakStore }
    },
    computed: {
    ...mapGetters("promotions", ["marketingPrograms"]),
      dateRangeText () {
        return this.dateRange.join(' ~ ')
      },
      mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;
        return this.marketingProgramStore;
      },
      kl() {
        return this.keycloakStore;
      },
      quizesApi() {
        if (!this.mp)
          return null;
        return quizesApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },
  },
  methods: {
    ...mapActions("promotions", ["getMarketingPrograms"]),
    formatDate(date) {
      return Moment(date).format('DD.MM.YYYY');
    },
    clearDateRange () {
      this.dateRange = []
    },
    surveyCsv() {
      console.log(this.marketingProgramId, this.surveyId, this.dateRange)
    },
    surveyShow() {
      if (!this.valid) return;
      this.surveyTableShow = true;
      this.activeCoreFiltersHash = Date.now();
    },
    async surveyTableUpdated(pagination, sorting) {
      await this.getSurvey(pagination, sorting);
    },
    async getSurvey(pagination, sorting) {
      if (!this.quizesApi) return;
      this.valid = await this.$refs.form.validate();
      if (!this.valid) return;
      this.surveyUpdating = true;
      try {
        const response = await this.quizesApi.getShort(
          pagination,
          sorting,
          this.surveyId,
          this.dateRange[0] ? Moment(this.dateRange[0]).add(3, 'hours').toISOString() : null,
          this.dateRange[1] ? Moment(this.dateRange[1]).add(3, 'hours').toISOString() : null,
        );
        const { values, total } = response.data;
        this.surveyItems = values;
        this.surveyTotal = total;
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      } finally {
        this.surveyUpdating = false;
      }
    },
    async getSurveyCsv() {
      this.valid = await this.$refs.form.validate();
      if (!this.valid) return;
      // if (!this.quizesApi) return;
      const response = await this.quizesApi.getQuizesCsv(
        this.surveyId,
        this.dateRange[0] ? Moment(this.dateRange[0]).add(3, 'hours').toISOString() : null,
        this.dateRange[1] ? Moment(this.dateRange[1]).add(3, 'hours').toISOString() : null,
      );
      const fileUrl = window.URL.createObjectURL(response.data);
      const anchor = document.createElement("a");
      anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
      anchor.href = fileUrl;
      anchor.target = "_blank";
      anchor.click();
      anchor.remove();
    },
    async getQuizes() {
      await this.quizesApi.getQuizes()
      .then (response => {
        if (response.status === 200) {
          this.quizes = response.data;
        }
      })
    },

  },
  mounted() {
    this.getMarketingPrograms();
    this.getQuizes();
  }
}
</script>

<style lang="scss" scoped>

</style>