<template>
  <div>
    <v-row>
      <v-col class="d-flex align-center">
        <p class="participant__info-title-2">Активность участника</p>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn large @click="participantTrainingDialogShow = true">
          <v-icon left>mdi-plus-circle</v-icon>
          тренировка
        </v-btn>
        <v-btn class="ml-3" large @click="participantExercisesDialogShow = true">
          <v-icon left>mdi-plus-circle</v-icon>
          задание
        </v-btn>
      </v-col>
      <!-- <v-col class="buttons">
        <div class="buttons-block" v-if="!kl.isOrgMp">
          <button v-if="activeParticipantTrackerId && participantState === 'Active'" class="buttons__edit" @click="participantTrainingDialogShow = true"><v-icon>mdi-plus</v-icon>Тренировка</button>
          <button v-if="!activeParticipantTrackerId || participantState !== 'Active'" class="buttons__edit" disabled>Трекер не подключен</button>
          <button class="buttons__edit" @click="participantExercisesDialogShow = true"><v-icon>mdi-plus</v-icon>Задание</button>
        </div>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col md="6" lg="3">
        <div class="participant__info-item">
            <v-icon large color="#0000008A">
              mdi-calendar-outline
            </v-icon>
            <div>
              <p class="participant__info-title">Последняя активность</p>
              <p class="participant__info-text">{{ lastActivityDate | formatDateTimeRus }}</p>
            </div>
          </div>
      </v-col>
      <v-col md="6" lg="3">
        <div class="participant__info-item">
            <v-icon large color="#0000008A">
              mdi-calendar-outline
            </v-icon>
            <div>
              <p class="participant__info-title">Последняя тренировка</p>
              <p class="participant__info-text">{{ lastActivityDate | formatDateTimeRus }}</p>
            </div>
          </div>
      </v-col>
      <v-col md="12" lg="6" class="d-flex justify-end">
        <v-chip
          v-for="type in filterTypeValues"
          :key="`filter-type-${type.value}`"
          class="ml-2"
          label
          small
          dark
          :color="filters.operationTypes.includes(type.value) ? 'accent' : '#616161E5'"
          @click="toggleTypeFilter(type.value)"
        >
          {{type.text}}
        </v-chip>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :expanded.sync="expanded"
      :server-items-length="total"
      item-key="operationId"
      :show-expand="true"
      @update:expanded="clickExpanded"
      @update:options="tableUpdated"
      single-expand
      @item-expanded="showExpand"
      :footer-props="footerProps"
    >
      <template v-slot:[`item.icon`]="{item}">
        <v-icon color="#000" v-if="item.operationType === 'SportActivity'">mdi-timer</v-icon>
        <!-- <v-icon color="#000" v-if="item.operationType === 'SportActivity'">mdi-delete</v-icon> -->
        <v-icon color="#000" v-if="item.operationType === 'Achievement'">mdi-medal-outline</v-icon>
        <v-icon color="#000" v-if="item.operationType === 'ParticipantExperience'">mdi-lightbulb</v-icon>
        <v-icon color="#000" v-if="item.operationType === 'NominationParticipant'">mdi-chart-line-variant</v-icon>
        <v-icon color="#000" v-if="item.operationType === 'BalanceOperation'">mdi-star</v-icon>
        <v-icon color="#000" v-if="item.operationType === 'ParticipantExercise'">mdi-checkbox-marked-circle-outline</v-icon>
      </template>
      <template v-slot:[`item.operationType`]="{item}">
        {{ getOperationTypeText(item) }}
      </template>
        <template v-slot:[`item.operationInfo`]="{item}">
          <component v-bind:is="`OperationInfo${getOperationInfoComponentName(item)}`"
            :operationSourceId="item.operationSourceId" :sourceId="item.sourceId" :attributes="item.attributes" :refresh="refreshData">
          </component>
        </template>
        <template v-slot:[`item.operationDate`]="{item}">
          {{ item.operationDate | formatDateTimeRus }}
        </template>
        <template v-slot:[`item.actions`]="{item}">
          <v-icon @click="cancelExercise(item)" 
            hint="Отменить"
            v-bind:style="{ opacity: cancelExerciseLoading ? .5 : 'inherit' }"
            v-if="!kl.isOrgMp && item.operationType === 'ParticipantExercise' && item.attributes.StateOperation === 'Created' && item.attributes.WasRejected !== 'True'">mdi-close-box</v-icon>
          &nbsp;
        </template>
        <template v-slot:[`item.data-table-expand`]="{ item }">
          <v-icon v-if="item.operationType === 'SportActivity'" @click.stop="toggleExpand(item)">
            {{ expanded.includes(item) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td v-if="item.operationType === 'SportActivity'" :colspan="headers.length">
            <v-data-table
              hide-default-footer
              :headers="headers1"
              :items="items1"
              class="table__expanded"
              :loading="loading"
            >
            <template v-slot:loading>Загрузка...</template>
            <template v-slot:no-data>Нет данных</template>
            <template v-slot:[`item.icon`]="{item}">
              <div class="d-flex">
                <v-icon color="#FF8233">mdi-arrow-right-bottom</v-icon>
                <v-icon color="#000" v-if="item.exerciseTitle && item.exerciseTaskTitle || item.exerciseTaskTitle && !item.exerciseTitle && !item.exerciseTaskReward">mdi-checkbox-marked-circle-outline</v-icon>
                <!-- <v-icon color="#000" v-if="!item.exerciseTaskReward && !item.exerciseTitle && item.exerciseTaskTitle">mdi-lightbulb</v-icon> -->
                <v-icon color="#000" v-if="!item.exerciseTitle && item.exerciseTaskTitle && item.exerciseTaskReward">mdi-star</v-icon>
              </div>
            </template>
          <template v-slot:[`item.operationDate`]="{item}">
            {{ item.operationDate | formatDateTimeRus }}
          </template>
          <template v-slot:[`item.type`]="{item}">
            <div v-if="item.exerciseTitle && item.exerciseTaskTitle">Выполнена работа</div>
            <div v-if="!item.exerciseTaskReward && !item.exerciseTitle && item.exerciseTaskTitle">Выполнено задание</div>
            <div v-if="!item.exerciseTitle && item.exerciseTaskTitle && item.exerciseTaskReward">Зачислены баллы</div>
          </template>
          <template v-slot:[`item.info`]="{item}">
            <!-- {{ item }} -->
            <div v-if="item.exerciseTitle && item.exerciseTaskTitle">
              В задании
              <router-link :to="`/exercises/${item.exerciseId}`" style="color: #FF8233">
                {{ item.exerciseTitle }}
              </router-link>
            </div>
            <div v-if="!item.exerciseTitle && item.exerciseTaskTitle && !item.exerciseTaskReward">{{ item.exerciseTaskTitle }}</div>
            <div v-if="item.exerciseTaskReward && !item.exerciseTitle && item.exerciseTaskTitle">{{ item.exerciseTaskTitle }} | Зачислены баллы <span style="color: #FF8233">{{ item.exerciseTaskReward }}</span></div>
          </template>
            </v-data-table>
          </td>
        </template>
    </v-data-table>
    <ParticipantTrainingEditorDialog
      v-if="participantTrainingDialogShow"
      :tracker-id="activeParticipantTrackerId"
      :save-func="saveParticipantTraining"
      @closed="participantTrainingDialogShow = false"
    />

    <ParticipantExercisesEditorDialog
      v-if="participantExercisesDialogShow"
      :participant-id="participantId"
      :save-func="saveParticipantExercises"
      @closed="participantExercisesDialogShow = false"
    />

    <v-dialog v-model="dialog.opened" width="720">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text v-html="dialog.text">
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <notifications :duration="5000" position="bottom right"/>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import { useKeycloakStore } from '@/stores/keycloakStore';
import scountApiInitializer from '@/api/scountApi';
import sportDataApiInitializer from '@/api/sportDataApi';
import operationsHistoryApiInitializer from '@/api/operationsHistoryApi';
import OperationInfoAchievement from './OperationInfo/OperationInfoAchievement.vue';
import OperationInfoBalanceOperationRefill from './OperationInfo/OperationInfoBalanceOperationRefill.vue';
import OperationInfoBalanceOperationWithdrawal from './OperationInfo/OperationInfoBalanceOperationWithdrawal.vue';
import OperationInfoNominationParticipant from './OperationInfo/OperationInfoNominationParticipant.vue';
import OperationInfoParticipantExerciseRejected from './OperationInfo/OperationInfoParticipantExerciseRejected.vue';
import OperationInfoParticipantExerciseRejectedManually from './OperationInfo/OperationInfoParticipantExerciseRejected.vue';
import OperationInfoParticipantExerciseCompleted from './OperationInfo/OperationInfoParticipantExerciseCompleted.vue';
import OperationInfoParticipantExerciseCreated from './OperationInfo/OperationInfoParticipantExerciseCreated.vue';
import OperationInfoParticipantExperience from './OperationInfo/OperationInfoParticipantExperience.vue';
import OperationInfoParticipantExperienceLevel from './OperationInfo/OperationInfoParticipantExperienceLevel.vue';
import OperationInfoPurchase from './OperationInfo/OperationInfoPurchase.vue';
import OperationInfoSportActivity from './OperationInfo/OperationInfoSportActivity.vue';
import OperationInfoTeamParticipant from './OperationInfo/OperationInfoTeamParticipant.vue';
import ParticipantTrainingEditorDialog from './ParticipantTrainingEditorDialog.vue';
import ParticipantExercisesEditorDialog from './ParticipantExercisesEditorDialog.vue';
import Moment from 'moment'
import OperationInfoParticipantSteps from './OperationInfo/OperationStepParticipant.vue';

// import TranningIcon from '@/assets/icon/tranning.svg';

export default {
  name: 'ParticipantOperationsHistoryList',
  components: {
    OperationInfoAchievement,
    OperationInfoBalanceOperationRefill,
    OperationInfoBalanceOperationWithdrawal,
    OperationInfoNominationParticipant,
    OperationInfoParticipantExerciseCompleted,
    OperationInfoParticipantExerciseRejected,
    OperationInfoParticipantExerciseRejectedManually,
    OperationInfoParticipantExerciseCreated,
    OperationInfoParticipantExperience,
    OperationInfoParticipantExperienceLevel,
    OperationInfoPurchase,
    OperationInfoSportActivity,
    ParticipantTrainingEditorDialog,
    ParticipantExercisesEditorDialog,
    OperationInfoTeamParticipant,
    OperationInfoParticipantSteps,
  },

  props: {
    participantId: {
      type: String,
    },
    participantState: {
      type: String,
    },
    saveFunc: Function,
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    const keycloakStore = useKeycloakStore()
    return { marketingProgramStore, keycloakStore }
  },
  data() {
    return {
      footerProps: {
        'items-per-page-options': [5, 10, 20, 50, 100],
        'items-per-page-text': 'Записей на странице:',
        'show-current-page': true,
        'show-first-last-page': true,
      },
      // TranningIcon,
      tranningActive: false,
      expanded: [],
      headers: [
        { value: 'icon', text: '', align: 'start', sortable: false },
        { value: 'operationType', text: 'Тип', align: 'start', sortable: false },
        { value: 'operationInfo', text: 'Операция', align: 'start', sortable: false },
        { value: 'operationDate', text: 'Дата', align: 'end', sortable: false },
        { value: 'actions', text: 'Действия', align: 'center', width: 120, sortable: false },
        { value: 'data-table-expand', text: '' },
      ],
      headers1: [
        { value: 'icon', text: '', align: 'start', width: 70, sortable: false },
        { value: 'type', text: '', align: 'start', width: 170, sortable: false },
        { value: 'info', text: '', align: 'start', sortable: false },
      ],
      items1: [],
      items: [],
      total: 0,
      selectedTypes: [],
      filters: {
        operationTypes: []
      },
      filtersHash: Date.now(),
      filterTypeValues: [
        {text: 'Задания', value: 'ParticipantExercise'},
        {text: 'Тренировки', value: 'SportActivity'},
        {text: 'Достижения', value: 'Achievement'},
        {text: 'Опыт', value: 'ParticipantExperience'},
        {text: 'Уровни', value: 'ParticipantExperienceLevel'},
        {text: 'Баланс', value: 'BalanceOperation'},
        {text: 'Покупки', value: 'Purchase'},
        {text: 'Очки рейтинга', value: 'NominationParticipant'},
      ],
      updating: false,
      activeParticipantTrackerId: undefined,
      participantTrainingDialogShow: false,
      participantExercisesDialogShow: false,
      pagination: undefined,
      sorting: undefined,
      cancelExerciseLoading: false,
      dialog: {
        opened: false,
        title: undefined,
        text: undefined
      },
      loading: false,
      lastActivityDate: undefined,
    }
  },
  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    kl() {
      return this.keycloakStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },

    sportDataApi() {
      if (!this.mp)
        return null;

      return sportDataApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },

    operationsHistoryApi() {
      if (!this.mp)
        return null;

      return operationsHistoryApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    }
  },

  methods: {
    toggleExpand(item) {
      // Если элемент уже открыт, закрываем его
      if (this.expanded.includes(item)) {
        this.expanded = []; // Закрываем все
      } else {
        this.expanded = [item]; // Открываем только текущий элемент
        this.showExpand(item); // Вызов метода при открытии строки
      }
    },
    handleRowOpen(item) {
      console.log('handleRowOpen', item);
      // Ваш код для обработки открытия строки
      this.showExpand(item);
      // Дополнительные действия...
    },
    showExpand(item) {
      this.items1 = [];
      this.loading = true;
      if(item && item.operationType === 'SportActivity') {
        const activityId = item && item ? item.operationSourceId : undefined;
        this.getActivitesParticipant(this.participantId, activityId)
        .then(response => {
          console.log('response', response);
          if (response) {
            this.loading = false;
            const selectedFields = [
              'exerciseTitle',
              'exerciseTaskTitle',
              'exerciseTaskReward'
            ];
            this.items1 = selectedFields.map(key => ({
              [key]: response.data[key],
              exerciseTaskTitle: response.data.exerciseTitle,
              exerciseId: response.data.exerciseId
            }));
          }
        })
        .catch(error => {
          console.log('error', error);
          this.items1 = [];
          this.loading = false;
          if(error.status !== 404) {
            this.$notify({ type: 'error', text: `Ошибка при получении данных` });
          }
        }).finally(() => {
          this.loading = false;
        })
      }
      console.log('showExpand', item);
      // Возвращаем true только если item является 'SportActivity'
      // return item.operationType === 'SportActivity';
    },
    async getActivitesParticipant(participantId, activityId) {
      const response = await this.scountApi.getParticipantsActivities(participantId, activityId);
      return response;
    },
    clickExpanded(item) {
      // const activityId = item && item[0] ? item[0].operationSourceId : undefined;
      // this.getActivitesParticipant(this.participantId, activityId);
        console.log('clickExpanded', item);
    },
    async cancelExercise(item) {
      if (this.cancelExerciseLoading)
        return;
      this.cancelExerciseLoading = true;
      const resp = await this.scountApi.cancelParticipantExercise(item.operationSourceId);
      if (resp.success) {
        const ths = this;
        setTimeout(() => {
          ths.cancelExerciseLoading = false;
          ths.refreshData();
        }, 2000);
      }
    },

    async refreshData() {
      await this.updateData(this.pagination, this.sorting);
    },

    async updateData(pagination, sorting) {
      await this.getOperations(pagination, sorting, {
        operationTypes: this.filters.operationTypes.join(',') // Передаем выбранные фильтры
      });
    },

    async tableUpdated(options) {
      const pagination = {
        offset: (options.page - 1) * options.itemsPerPage,
        count: options.itemsPerPage,
      };
      const sorting = {
        field: options.sortBy[0],
        direction: options.sortDesc[0] ? 'desc' : 'asc',
      };

      // Обновляем URL
      this.updateUrlWithFilters();

      await this.updateData(pagination, sorting);
    },

    async getOperations(pagination, sorting, filters) {
      if (!this.operationsHistoryApi) return;

      this.updating = true;

      try {
        const response = await this.operationsHistoryApi.getOperations(
          this.participantId,
          pagination,
          sorting,
          filters,
        );

        const {values, total} = response.data;

        this.total = total;
        this.items = values;
        this.lastActivityDate = this.items[0] ? this.items[0].operationDate : undefined;
      } finally {
        this.updating = false;
      }
    },

    async saveParticipantTraining(model) {
      this.updating = true;

      try {
        const trackerId = model.trackerId;

        const averagePace = model.timeInSeconds / (0.001 * model.distance);
        const averageSpeed = 0.001 * model.distance / (model.timeInSeconds / 3600);

        const request = {
          activityId: uuid.v4(),
          externalId: uuid.v4(),
          discipline: model.discipline,
          startDate: Moment(model.startDate, 'DD.MM.YYYY hh:mm').toDate(),
          finishDate: Moment(model.startDate, 'DD.MM.YYYY hh:mm').add(model.timeInSeconds, "seconds").toDate(),
          distance: model.distance,
          timeInSeconds: model.timeInSeconds,
          averagePace: averagePace,
          averageSpeed: averageSpeed,
        };
        
        const response = await this.sportDataApi.addParticipantTraining(trackerId, request);

        if (response.success) {
            setTimeout(() => {
              this.filtersHash = Date.now();
              this.updating = false;
            }, 3000);
          }
          else {
            const e = response.error;
            const errorText = !e
              ? 'Произошла ошибка'
              : `Код статуса ответа: ${e.status}`
                + `<br>Код: ${e.code}`
                + `<br>Ошибка: ${e.title}`
                + `<br>Подробности: ${e.detail}`;

            this.openDialog('Ошибка при добавлении тренировки', errorText); 
            this.updating = false;
          }
      } catch {
        this.updating = false;
      }
    },

    async saveParticipantExercises(model) {
      this.updating = true;

      try {
        for (let i = 0; i < model.length; i++) {
          const participantExerciseId = uuid.v4();
          const response = await this.scountApi.addParticipantExercise(participantExerciseId, this.participantId, model[i]);
          if (response.success) {
            setTimeout(() => {
              this.filtersHash = Date.now();
              this.updating = false;
            }, 1000);
          } else {
            const e = response.error;
            const errorText = !e
              ? 'Произошла ошибка'
              : `Код статуса ответа: ${e.status}`
                + `<br>Код: ${e.code}`
                + `<br>Ошибка: ${e.title}`
                + `<br>Подробности: ${e.detail}`;

            this.openDialog('Ошибка при добавлении задания', errorText); 
            this.updating = false;
          }
        }
      } catch {
        this.updating = false;
      }
    },

    openDialog(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    closeDialog() {
      this.dialog.opened = false;
    },

    toggleTypeFilter(type) {
      const index = this.filters.operationTypes.indexOf(type);
      if (index !== -1) {
        this.filters.operationTypes.splice(index, 1);
      } else {
        this.filters.operationTypes.push(type);
      }
      this.updateUrlWithFilters();

      this.filtersHash = Date.now();
      this.pagination = { offset: 0, count: 10 };
      this.refreshData()
        .then(() => {
          if (type === 'SportActivity') {
            this.tranningActive = !this.tranningActive;
          }
        });
    },

    updateUrlWithFilters() {
      const query = {
        page: this.$route.query.page || 1,
        itemsPerPage: this.$route.query.itemsPerPage || 10,
        sortBy: this.$route.query.sortBy || 'operationDate',
        sortDesc: this.$route.query.sortDesc || false,
        operationTypes: this.filters.operationTypes.join(','), // Добавляем выбранные типы операций
      };

      const hash = `#date=${new Date().toISOString()}`; // Создаем хэш с текущей датой

      this.$router.push({ query, hash }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.error(err); // Логируем ошибку, если это не NavigationDuplicated
        }
      });
    },
    getOperationTypeText(operation) {
      switch (operation.operationType) {
        case 'ParticipantSteps': return 'Зачислены баллы';
        case 'TeamParticipant':
          if (operation.attributes.StateOperation === 'Created') {
            return 'Вступил в команду';
          } else return 'Вышел из команды'
        case 'Achievement': return 'Новое достижение';
        case 'BalanceOperation': return operation.attributes.OperationType === 'Refill' ? 'Зачисление': 'Списание';
        case 'ParticipantExercise':
          if (operation.attributes.StateOperation === 'Created') {
            return 'Новое задание';
          } else if (operation.attributes.StateOperation === 'RejectedManually' && operation.attributes.IsRejectedByAdmin === "True") {
            return 'Задание отменено администратором';
          } else if (operation.attributes.StateOperation === 'RejectedManually' && operation.attributes.IsRejectedByAdmin !== "True") {
            return 'Задание отменено пользователем';
          } else {
            return 'Задание выполнено';
          }
        case 'ParticipantExperience': return 'Получен опыт';
        case 'ParticipantExperienceLevel': return 'Новый уровень';
        case 'Purchase': return 'Покупка';
        case 'SportActivity': return 'Тренировка';
        case 'NominationParticipant': return 'Рейтинг изменен';
      }
    },

    getOperationInfoComponentName(operation) {
      switch (operation.operationType) {
        case 'TeamParticipant': return operation.operationType;
        case 'BalanceOperation': return operation.operationType + operation.attributes.OperationType;
        case 'ParticipantExercise': return operation.operationType + operation.attributes.StateOperation;
        default: return operation.operationType;
      }
    },

    tableRowClicked() {
      // this.goToParticipant(participant.participantId);
    },

    goToParticipant(participantId) {
      this.$router.push(`/participants/${participantId}`);
    },

    async getActiveParticipantTracker() {
        const response = await this.sportDataApi.getActiveParticipantTrackerId(this.participantId);
        this.activeParticipantTrackerId = response.data;
    }
  },

  async mounted() {
    const { page = 1, itemsPerPage = 10, sortBy = 'operationDate', sortDesc = false, operationTypes = '' } = this.$route.query;

    // Устанавливаем параметры пагинации и сортировки
    this.pagination = { offset: (page - 1) * itemsPerPage, count: itemsPerPage };
    this.sorting = { field: sortBy, direction: sortDesc === 'true' ? 'desc' : 'asc' };

    // Устанавливаем фильтры
    this.filters.operationTypes = operationTypes.split(',').filter(Boolean);

    // Обработка хэша
    const hash = this.$route.hash;
    if (hash) {
      console.log('Current hash:', hash);
      // Дополнительные действия...
    }

    await this.getActiveParticipantTracker();
    await this.updateData(this.pagination, this.sorting);
  }
}
</script>
<style lang="scss">
  @import '../../assets/global';
  @import '../../assets/participants';
</style>