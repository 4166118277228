<template>
  <div class="participant pa-4">
    <div v-if="participant">
      <v-row>
        <v-col cols="auto">
          <div class="d-flex align-center">
            <v-avatar color="#0000008A">
              <v-icon dark>
                mdi-account
              </v-icon>
            </v-avatar>
            <div class="ml-4">
              <p class="participant__title mb-1">Участник: {{participantInfo.fio}}</p>
              <div class="participant__subtitle">
                <span class="mr-2">ID:{{participantId}}</span>|
                <span class="ml-1">Регистрация в команде: {{ participantInfo.createOn }}</span>
              </div>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="d-flex justify-end align-center participant__buttons">
            <v-btn
              v-if="!kl.isOrgMp"
              large
              @click="participantProfileDialogShow = true"
              class="mr-3"
            >
              <v-icon left>mdi-pencil</v-icon>
              Редактировать
            </v-btn>
            <v-btn v-if="!kl.isOrgMp" large @click="participantBlock"><v-icon :color="participant.state === 'Active' ? '#666' : '#b00'">mdi-diameter-variant</v-icon></v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col cols="3">
          <div class="participant__info-item">
            <v-icon large color="#0000008A">
              mdi-gender-{{participantInfo.gender === 'Male' ? 'male' : 'female'}}
            </v-icon>
            <div>
              <p class="participant__info-title">Пол</p>
              <p class="participant__info-text">{{participantInfo.gender === 'Male' ? 'Мужчина' : 'Женщина'}}</p>
            </div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="participant__info-item">
            <v-icon large color="#0000008A">
              mdi-cake
            </v-icon>
            <div>
              <p class="participant__info-title">Дата рождения</p>
              <p class="participant__info-text">
                {{participantInfo.birthday | formatDate('DD.MM.YYYY')}} | {{participantInfo.age}}
              </p>
            </div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="participant__info-item">
            <v-icon large color="#0000008A">
              mdi-at
            </v-icon>
            <div>
              <p class="participant__info-title">Email</p>
              <p class="participant__info-text">
                {{profile.emailAddress}}
              </p>
            </div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="participant__info-item">
            <v-icon large color="#0000008A">
              mdi-message-text
            </v-icon>
            <div>
              <p class="participant__info-title">соц.сеть/мессенджер</p>
              <p class="participant__info-text">
                -
              </p>
            </div>
          </div>
        </v-col>
        <v-col v-if="participantInfo && participantInfo.teamName" cols="3">
          <div class="participant__info-item">
            <v-icon large color="#0000008A">
              mdi-account-group
            </v-icon>
            <div>
              <p class="participant__info-title">Команда</p>
              <p class="participant__info-text">
                {{participantInfo.teamName}}
              </p>
            </div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="participant__info-item">
            <img src="../../assets/icon/bell.svg" alt="">
            <div>
              <p class="participant__info-title">Уведомления</p>
              <p class="participant__info-text">
                Выключены
              </p>
            </div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="participant__info-item">
            <img src="../../assets/icon/tracker.svg" alt="">
            <div>
              <p class="participant__info-title">Активный Трекер</p>
              <p class="participant__info-text">
                -
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-divider class="my-8"></v-divider>
      <v-row>
        <v-col class="d-flex align-center">
          <p class="participant__info-title-2">Достижения участника</p>
        </v-col>
        <v-col>
          <div class="d-flex justify-end align-center participant__buttons">
            <v-btn
              v-if="!kl.isOrgMp"
              large
              @click="participantBalanceDialogShow = true"
            >
              <v-icon left>mdi-pencil</v-icon>
              Редактировать
            </v-btn>
          </div>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="3">
          <div class="participant__info-item">
            <img src="../../assets/icon/scores.svg" alt="">
            <div>
              <p class="participant__info-title">Баллы</p>
              <p class="participant__info-text">
                {{participant.balance.amount}}
              </p>
            </div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="participant__info-item">
            <img src="../../assets/icon/points.svg" alt="">
            <div>
              <p class="participant__info-title">Очки рейтинга</p>
              <p class="participant__info-text">
                -
              </p>
            </div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="participant__info-item">
            <img src="../../assets/icon/xp.svg" alt="">
            <div>
              <p class="participant__info-title">Уровень, XP</p>
              <p class="participant__info-text">
                {{participant.experience.level}}
              </p>
            </div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="participant__info-item">
            <img src="../../assets/icon/goal.svg" alt="">
            <div>
              <p class="participant__info-title">Вклад в общую цель</p>
              <p class="participant__info-text">
                {{participant.goalContributionTotalDistance}}
              </p>
            </div>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="participant__info-item">
            <img src="../../assets/icon/achivments.svg" alt="">
            <div>
              <p class="participant__info-title">Получено ачивок</p>
              <p class="participant__info-text">
                {{participant.achievements.length}}
              </p>
            </div>
          </div>
        </v-col>
        <!-- <v-col cols="9">
          <div class="d-flex">
            <div class="participant__achievement" v-for="(type, index) in achievementTypes" :key="index">
              <img :src="require(`@/assets/images/achievements/${type}.png`)" alt="">
            </div>
          </div>
        </v-col> -->
      </v-row>
      <v-divider class="my-8"></v-divider>
      <!-- <ParticipantOperationsHistoryList :participant-id="participantId" :participant-state="participant.state" /> -->
      <ParticipantActivityTable :participant-id="participantId" :participant-state="participant.state" />
      <ParticipantProfileEditorDialog
        v-if="participantProfileDialogShow"
        :object="profile"
        :allMpTeams="allMpTeams"
        :save-func="saveParticipantProfile"
        @closed="participantProfileDialogShow = false"
      />
      <ParticipantBalanceEditorDialog
        v-if="participantBalanceDialogShow"
        :object="balance"
        :save-func="saveParticipantBalance"
        @closed="participantBalanceDialogShow = false"
      />
      
      <v-dialog v-model="dialog.opened" width="720">
        <v-card>
          <v-card-title class="headline">{{ dialog.title }}</v-card-title>
          <v-card-text v-html="dialog.text">
          </v-card-text>
          <v-card-actions>
            <v-btn @click="hideError">Закрыть</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <v-overlay :value="loading"></v-overlay>
    </div>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import { useKeycloakStore } from '@/stores/keycloakStore';
import scountApiInitializer from '@/api/scountApi';
// import ParticipantOperationsHistoryList from './ParticipantOperationsHistoryList.vue';
import ParticipantProfileEditorDialog from './ParticipantProfileEditorDialog.vue';
import ParticipantBalanceEditorDialog from './ParticipantBalanceEditorDialog.vue';
import Moment from 'moment'

import ParticipantActivityTable from './ParticipantActivityTable.vue';

export default {
  name: 'ParticipantView',
  components: { ParticipantProfileEditorDialog, ParticipantBalanceEditorDialog, ParticipantActivityTable },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    const keycloakStore = useKeycloakStore()
    return { marketingProgramStore, keycloakStore }
  },

  data: () => ({
    participant: null,
    dialog: {
      opened: false,
      title: undefined,
      text: undefined
    },
    profile: {
      fio: undefined,
      gender: undefined,
      birthday: undefined,
      emailAddress: undefined,
      teamId: undefined,
    },
    allMpTeams: [],
    balance: { 
      amount: undefined
    },
    loading: false,
    participantProfileDialogShow: false,
    participantBalanceDialogShow: false,
    achievementTypes: [
      "Welcome", 'Walked25Kilometers', 'Walked42Kilometers', 'Walked50Kilometers', 'Walked100Kilometers',  'Walked300Kilometers', 'Walked500Kilometers',
    ],
  }),

  computed: {
    participantId() {
      return this.$route.params.participantId;
    },

    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    kl() {
      return this.keycloakStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },

    participantInfo() {
      if (!this.participant)
        return null;

      var emailAddress = this.getAnswersValue('EmailAddress');
      var firstName = this.getAnswersValue('FirstName');
      var lastName = this.getAnswersValue('LastName');

      var gender = this.getAnswersValue('Gender');
      var birthday = this.getAnswersValue('Birthday');

      const teamId = this.getAnswersValue('TeamId');
      const createOn = this.participant.createdOn ? Moment(this.participant.createdOn).format('DD.MM.YYYY') : "";

      var today = new Date();
      var birthDate = new Date(birthday);
      var years = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          years--;
      }

      const endOfAge = (() => { 
        if (years < 0)
          return "";

        const cm10 = years % 10;
        const cm100 = years % 100;

        if (cm100 >= 10 && cm100 <= 20)
            return "лет";

        if (cm10 == 1)
            return "год";

        if (cm10 > 1 && cm10 < 5)
            return "года";

        return "лет";
       })();

      const age = `${years} ${endOfAge}`;

      var avatarUrl = null;
      if (!this.participant.avatar)
        avatarUrl = null;
      else
      {
        avatarUrl = this.participant.avatar.type === 'custom'
          ? this.participant.avatar.url
          : `/img/avatars/avatar-step${parseInt(this.participant.avatar.type) < 10 ? '0' : ''}${this.participant.avatar.type}.svg`;
      }

      let team = teamId ? this.allMpTeams.find(x => x.teamId === teamId) : "-";

      return {
        birthday,
        age,
        gender,
        avatarUrl,
        fio: `${firstName} ${lastName}`,
        emailAddress,
        teamName: team ? team.name : "-",
        createOn
      };
    },

    avatarUrl() {
      if (!this.participant || !this.participant.avatar)
        return null;

      return this.participant.avatar.type === 'custom'
        ? this.participant.avatar.url
        : `/img/avatars/avatar-step${parseInt(this.participant.avatar.type) < 10 ? '0' : ''}${this.participant.avatar.type}.svg`;
    }
  },

  methods: {
    async getParticipant() {
      const response = await this.scountApi.getParticipant(this.participantId);
      this.participant = response.data;
            
      const lastName = this.getAnswersValue('LastName');
      const firstName = this.getAnswersValue('FirstName');

      this.profile.lastName = lastName;
      this.profile.firstName = firstName;
      this.profile.gender = this.getAnswersValue('Gender');
      this.profile.birthday = this.getAnswersValue('Birthday');
      this.profile.emailAddress = this.getAnswersValue('EmailAddress');
      this.profile.teamId = this.getAnswersValue('TeamId');

      this.allMpTeams = await this.getAllTeams();

      this.balance.amount = this.participant.balance.amount;
    },

    async saveParticipantProfile(model) {
      this.loading = true;
      
      try {
        const request = {
          answers: {
            LastName: model.lastName,
            FirstName: model.firstName,
            Gender: model.gender,
            Birthday: model.birthday,
            EmailAddress: model.emailAddress,
            TeamId: model.teamId
          }
        };

        const resp = await this.scountApi.editParticipantQuestionnaire(this.participantId, request);
        if (resp.success)
          await this.getParticipant();
        else
          this.showError('Ошибка при замене адреса e-mail', this.getAfterSaveText(resp.error));
      } finally {
        this.loading = false;
      }
    },

    showError(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    hideError() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.opened = false;
    },

    getAfterSaveText(error) {
        const e = error;
        if (!e) return 'Произошла ошибка';
        if (e.status === 422 && e.detail && e.detail.startsWith('The user with email '))
          return 'Пользователь с указанным e-mail уже есть.';
        return e.detail;
    },

    async saveParticipantBalance(model) {
      this.loading = true;

      try {
        await this.scountApi.editParticipantBalance(this.participantId, model);
        await this.getParticipant();
      } finally {
        this.loading = false;
      }
    },
    
    async getAllTeams() {
      const total = await this.scountApi.getTeams();
      if (total.data.total >= 5) {
        const response = await this.scountApi.getTeams(1, null, null, total.data.total);
        return response.data.values;
      } else {
      const response = await this.scountApi.getTeams(1, null, null, 5);
        return response.data.values;
      }
    },

    async participantBlock() {
      this.loading = true;

      try {
        if (this.participant.state === 'Active')
          await this.scountApi.blockParticipant(this.participantId);
        else
          await this.scountApi.unblockParticipant(this.participantId);
        await this.getParticipant();
      } finally {
        this.loading = false;
      }
    },

    getAnswersValue(key) {
      const answer = this.participant.questionnaire.answers.find(a => a.key === key);
      return answer ? answer.value : undefined;
    },
  },

  async mounted() {
    this.loading = true;
    try {
      await this.getParticipant();
      
    } finally {
      this.loading = false;
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '../../assets/global';
  @import '../../assets/participants';
</style>