var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-6"},[_c('h2',{staticClass:"cmn__h1 mb-5"},[_vm._v("Новые участники")]),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Начало отчетного периода","rules":_vm.validation.date,"hide-details":"auto","append-outer-icon":"mdi-calendar-blank","placeholder":"ДД.ММ.ГГГГ"},model:{value:(_vm.newParticipantsParams.startDate),callback:function ($$v) {_vm.$set(_vm.newParticipantsParams, "startDate", $$v)},expression:"newParticipantsParams.startDate"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Конец отчетного периода","rules":_vm.validation.date,"append-outer-icon":"mdi-calendar-blank","placeholder":"ДД.ММ.ГГГГ","hide-details":"auto"},model:{value:(_vm.newParticipantsParams.endDate),callback:function ($$v) {_vm.$set(_vm.newParticipantsParams, "endDate", $$v)},expression:"newParticipantsParams.endDate"}})],1),_c('v-col',{staticClass:"d-flex align-end",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"ml-3",attrs:{"large":""},on:{"click":_vm.newParticipantsShow}},[_vm._v("Показать")]),_c('v-btn',{staticClass:"ml-3",attrs:{"large":""},on:{"click":_vm.newParticipantsCsv}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" Скачать в CSV")],1)],1)],1),_c('v-row',[_c('v-col',[(_vm.newParticipantsTableShow)?_c('DataTable',{key:_vm.newParticipantsFiltersHash,attrs:{"headers":_vm.newParticipantsHeaders,"items":_vm.newParticipantsItems,"loading":_vm.newParticipantsUpdating,"server-items-length":_vm.newParticipantsTotal},on:{"update:table-options":_vm.newParticipantsTableUpdated},scopedSlots:_vm._u([{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trancate"},[_vm._v(_vm._s(item.lastName))])]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trancate"},[_vm._v(_vm._s(item.firstName))])]}},{key:"item.registration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeRus")(item.registration))+" ")]}},{key:"item.organization",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trancate"},[_vm._v(_vm._s(item.organization ? item.organization : '-'))])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.phone ? item.phone : '-')+" ")]}},{key:"item.birthday",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateRus")(item.birthday))+" ")]}},{key:"item.homeAddress",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.homeAddress ? item.homeAddress : '-')+" ")]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.gender === 'Male' ? 'муж.' : item.gender === 'Female' ? 'жен.' : '-')+" ")]}},{key:"item.unionMemberTicket",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.unionMemberTicket ? item.unionMemberTicket : '-')+" ")]}},{key:"item.teamName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trancate"},[_vm._v(_vm._s(item.teamName ? item.teamName : '-'))])]}}],null,true)}):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }