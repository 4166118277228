<template>
  <div>
    <v-row>
      <v-col>
        <v-btn @click="openCreateTaskDialog">Добавить работу</v-btn>
      </v-col>
    </v-row>
    <DataTable
      :key="filtersHash"
      controller="inRoute"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="date"
      :sort-desc="true"
      :search-fields="searchFields"
      :loading="updating"
      @update:table-options="tableUpdated"
    >
      <template v-slot:[`item.title`]="{item}">
        {{ item.title }}
      </template>
      <template v-slot:[`item.type`]="{item}">
        {{ getTypeDisplay(item.type) }}
      </template>
      <template v-slot:[`item.order`]="{item}">
        {{ item.order }}
      </template>
      <template v-slot:[`item.reward`]="{item}">
        {{ item.reward }}
      </template>
      <template v-slot:[`item.settings`]="{item}">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="mr-2">
                {{ getDiscipline(item.settings.Discipline).icon }}
              </v-icon>
            </span>
          </template>
          <span>Дисциплина: {{ getDiscipline(item.settings.Discipline).text }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.createdOn`]="{item}">
        {{ item.createdOn | formatDate }}
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-icon class="mr-2" @click="tableRowClicked(item)">mdi-pencil</v-icon>
      </template>
    </DataTable>

    <ExerciseTaskEditorDialog
      v-if="exerciseTaskEditorDialogShow"
      :object-id="taskId"
      :object="task"
      :exercise-type="exerciseType"
      :save-func="saveExerciseTask"
      @closed="exerciseTaskEditorDialogShow = false"
      formTask="formTask"
    />
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import scountApiInitializer from '@/api/scountApi';
import ExerciseTaskEditorDialog from './ExerciseTaskEditorDialog.vue';

export default {
  name: 'ExerciseTasksView',
  components: { ExerciseTaskEditorDialog },
  
  props: {
    exerciseType: undefined
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    headers: [
      { value: 'title', text: 'Название', align: 'start', sortable: false },
      { value: 'type', text: 'Тип', align: 'start', sortable: false },
      { value: 'order', text: 'Порядок', align: 'start', sortable: false },
      { value: 'reward', text: 'Баллы', align: 'start', sortable: false },
      { value: 'maxReward', text: 'Баллы (max)', align: 'start', sortable: false },
      { value: 'settings', text: 'Настройки', align: 'start', sortable: false },
      { value: 'createdOn', text: 'Создан', align: 'end', sortable: false },
      { value: 'actions', text: 'Действия', align: 'center', sortable: false },
    ],
    filtersHash: Date.now(),
    items: [],
    total: 0,
    searchFields: [
      { key: 'title', label: 'Название' },
    ],
    updating: false,
    exerciseTaskEditorDialogShow: false,
    taskId: undefined,
    task: undefined
  }),

  computed: {
    exerciseId() {
      return this.$route.params.exerciseId;
    },

    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },
  },

  methods: {
    async tableUpdated() {
      await this.getExerciseTasks();
    },

    async getExerciseTasks() {
      if (!this.scountApi) return;

      this.updating = true;

      try {
        const response = await this.scountApi.getExerciseTasks(this.exerciseId);
        this.total = response.data.length;
        this.items = response.data;

      } finally {
        this.updating = false;
      }
    },

    async saveExerciseTask(model) {
      this.exerciseTaskEditorDialogShow = false;
      if (this.taskId) {
        await this.changeExerciseTask(model);
      } else {
        await this.createExerciseTask(model)
      }
    },

    async createExerciseTask(model) {
      var exerciseTaskId = uuid.v4();

      let request;
      switch (model.type) {
        case 'SportActivity':
          request = {
            type: model.type,
            title: model.title,
            order: model.order,
            reward: model.reward,
            maxReward: model.maxReward,
            settings: {
              MaxPace: model.settings.MaxPace, 
              MinPace: model.settings.MinPace, 
              Discipline: model.settings.Discipline, 
              MaxDistance: model.settings.MaxDistance, 
              MinDistance: model.settings.MinDistance,
              MinTimeInSeconds: model.settings.MinTimeInSeconds,
              MaxTimeInSeconds: model.settings.MaxTimeInSeconds
            }
          };
          break;
        case 'GeoLocation':
          model.settings.Locations.forEach(el => {
            const m = /^(-?\d{1,3}\.\d{1,6}), (-?\d{1,3}\.\d{1,6})$/.exec(el.value);
            el.Latitude = m[1];
            el.Longitude = m[2];
            delete el.value;
          });
          request = {
            type: model.type,
            title: model.title,
            order: model.order,
            reward: model.reward,
            maxReward: model.reward,
            settings: {
              Discipline: 'GeoLocation',
              Radius: model.settings.Radius,
              Locations: JSON.stringify(model.settings.Locations)
            }
          };
          break;
        case 'Quiz':
          request = {
            type: model.type,
            title: model.title,
            order: model.order,
            reward: model.reward,
            maxReward: model.reward,
            settings: {
              Discipline: 'Quiz',
              QuizId: model.settings.QuizId,
              QuizQuestionsQty: model.settings.QuizQuestionsQty
            }
          };
          break;
        case 'Survey':
          request = {
            type: model.type,
            title: model.title,
            order: model.order,
            reward: model.reward,
            maxReward: model.reward,
            settings: {
              Discipline: 'Survey',
              QuizId: model.settings.QuizId,
              SurveyQuestionsQty: model.settings.SurveyQuestionsQty
            }
          };
          break;
        case 'PartnerLink':
          request = {
            type: model.type,
            title: model.title,
            order: model.order,
            reward: model.reward,
            maxReward: model.reward,
            settings: {
              Discipline: 'PartnerLink',
              PartnerLink: model.settings.PartnerLink,
              PromocodeLink: model.settings.PromocodeLink
            }
          };
          break;
        case 'UploadImage':
          request = {
            type: model.type,
            title: model.title,
            order: model.order,
            reward: model.reward,
            maxReward: model.reward,
            settings: {
              Discipline: 'UploadImage',
              What: 'File',
              CheckTime: model.settings.CheckTime
            }
          };
          break;
        case 'Registration':
        if (typeof model.settings.Events[0].Races === 'string' && model.settings.Events[0].Races.trim() !== '') {
          model.settings.Events[0].Races = model.settings.Events[0].Races.split(',');
        } else if (Object.prototype.hasOwnProperty.call(model.settings.Events[0], 'Races')) {
          if (model.settings.Events[0].Races === '') {
              model.settings.Events[0].Races = undefined;
          }
        }
        if (typeof model.settings.Disciplines === 'string') {
          if (model.settings.Disciplines.trim() !== '') {
            model.settings.Disciplines = model.settings.Disciplines.split(',').map(item => item.trim());
          } else {
            model.settings.Disciplines = undefined;
          }
          } else if (Array.isArray(model.settings.Disciplines) && model.settings.Disciplines.length === 0) {
          model.settings.Disciplines = undefined;
        }
          request = {
            type: model.type,
            title: model.title,
            order: model.order,
            reward: +model.reward,
            maxReward: model.reward,
            settings: {
              Discipline: model.type,
              Link: model.settings.Link,
              Disciplines: JSON.stringify(model.settings.Disciplines),
              Events: JSON.stringify([
                {
                  EventId: model.settings.Events[0].EventId,
                  Races: model.settings.Events[0].Races
                }
              ])
            }
          };
          break;
        case 'Participation':
        if (typeof model.settings.Events[0].Races === 'string' && model.settings.Events[0].Races.trim() !== '') {
          model.settings.Events[0].Races = model.settings.Events[0].Races.split(',');
        } else if (Object.prototype.hasOwnProperty.call(model.settings.Events[0], 'Races')) {
          if (model.settings.Events[0].Races === '') {
              model.settings.Events[0].Races = undefined;
          }
        }
          request = {
            type: model.type,
            title: model.title,
            order: model.order,
            reward: model.reward,
            maxReward: model.reward,
            settings: {
              MaxTimeInSeconds: model.settings.MaxTimeInSeconds,
              Discipline: model.type,
              Events: JSON.stringify([
                {
                  EventId: model.settings.Events[0].EventId,
                  Races: model.settings.Events[0].Races
                }
              ])
            }
          };
          break;
        case 'Invite':
          request = {
            type: model.type,
            title: model.title,
            order: model.order,
            reward: model.reward,
            maxReward: model.maxReward,
            settings: {
              RegistrationCount: model.settings.RegistrationCount,
              Link: model.settings.Link,
              Discipline: model.type,
            }
          };
          break;
      }

      await this.scountApi.createExerciseTask(this.exerciseId, exerciseTaskId, request);
      this.goToExercise(exerciseTaskId);
    },

    async changeExerciseTask(model) {
      if (model.type === 'GeoLocation') {
        model.settings.Locations.forEach(el => {
          const m = /^(-?\d{1,3}\.\d{1,6}), (-?\d{1,3}\.\d{1,6})$/.exec(el.value);
          el.Latitude = m[1];
          el.Longitude = m[2];
        });
        model.settings.Locations = JSON.stringify(model.settings.Locations);
      }

      if (model.type !== 'SportActivity' && model.type !== 'Invite') {
        model.maxReward = model.reward;
      }

      if (model.type === 'Registration') {
        if (typeof model.settings.Events[0].Races === 'string' && model.settings.Events[0].Races.trim() !== '') {
          model.settings.Events[0].Races = model.settings.Events[0].Races.split(',');
        } else if (Object.prototype.hasOwnProperty.call(model.settings.Events[0], 'Races')) {
            if (model.settings.Events[0].Races === '') {
                model.settings.Events[0].Races = undefined;
            }
        }
        if (typeof model.settings.Disciplines === 'string') {
          if (model.settings.Disciplines.trim() !== '') {
            model.settings.Disciplines = model.settings.Disciplines.split(',').map(item => item.trim());
          } else {
            model.settings.Disciplines = undefined;
          }
          } else if (Array.isArray(model.settings.Disciplines) && model.settings.Disciplines.length === 0) {
          model.settings.Disciplines = undefined;
        }
        model.settings.Disciplines = JSON.stringify(model.settings.Disciplines);
        model.settings.Events = JSON.stringify(model.settings.Events);
      }

      if (model.type === 'Participation') {
        if (typeof model.settings.Events[0].Races === 'string' && model.settings.Events[0].Races.trim() !== '') {
          model.settings.Events[0].Races = model.settings.Events[0].Races.split(',');
        } else if (Object.prototype.hasOwnProperty.call(model.settings.Events[0], 'Races')) {
            if (model.settings.Events[0].Races === '') {
                model.settings.Events[0].Races = undefined;
            }
        }
        model.settings.Events = JSON.stringify(model.settings.Events);
        model.settings.Disciplines = undefined;
        model.settings.Discipline = model.type;
      }

      await this.scountApi.changeExerciseTask(this.exerciseId, this.taskId, model);
      this.filtersHash = Date.now();
    },

    openCreateTaskDialog() {
      this.taskId = undefined;
      this.task = undefined;
      this.exerciseTaskEditorDialogShow = true;
    },

    tableRowClicked(exerciseTask) {
      console.log('tableRowClicked', exerciseTask);
      this.taskId = exerciseTask.exerciseTaskId;
      this.task = exerciseTask;
      if (exerciseTask.type === 'Registration') {
        if (typeof exerciseTask.settings.Disciplines === 'string' || typeof exerciseTask.settings.Events === 'string'  ) {
          try {
            exerciseTask.settings.Disciplines = exerciseTask.settings.Disciplines ? JSON.parse(exerciseTask.settings.Disciplines) : undefined;
            exerciseTask.settings.Events = exerciseTask.settings.Events ? JSON.parse(exerciseTask.settings.Events) : undefined;
          } catch (error) {
            console.error('Ошибка разбора JSON:', error);
          }
        }
      }
      if (exerciseTask.type === 'Participation') {
        if (typeof exerciseTask.settings.Events === 'string'  ) {
          try {
            exerciseTask.settings.Events = exerciseTask.settings.Events ? JSON.parse(exerciseTask.settings.Events) : undefined;
          } catch (error) {
            console.error('Ошибка разбора JSON:', error);
          }
        }
      }
      if (exerciseTask.type === 'GeoLocation') {
        if (typeof exerciseTask.settings.Locations === 'string') {
          try {
            exerciseTask.settings.Locations = JSON.parse(exerciseTask.settings.Locations);
            exerciseTask.settings.Locations.forEach(el => {
              console.log(el);
              el.value = `${el.Latitude}, ${el.Longitude}`;
            });
          } catch (error) {
            console.error('Ошибка разбора JSON:', error);
          }
        }
      }

      this.exerciseTaskEditorDialogShow = true;
    },

    goToExercise() {
      this.$router.push(`/exercises/${this.exerciseId}`);
    },

    getDiscipline(discipline) {
      switch (discipline) {
        case 'Running': return { text: 'Бег', icon: 'mdi-run' };
        case 'Walking': return { text: 'Ходьба', icon: 'mdi-shoe-sneaker' };
        case 'Cycling': return { text: 'Велосипед', icon: 'mdi-bicycle' };
        case 'Swimming': return { text: 'Плавание', icon: 'mdi-pool' };
        case 'Skiing': return { text: 'Лыжи', icon: 'mdi-ski' };
        case 'GeoLocation': return { text: 'Лыжи', icon: 'mdi-map-marker' };
        case 'Registration': return { text: 'Лыжи', icon: 'mdi-file-document-check' };
        case 'Participation': return { text: 'Лыжи', icon: 'mdi-calendar-check-outline' };

        case 'Quiz': return { text: 'Квиз', icon: 'mdi-head-question' };
        case 'PartnerLink': return { text: 'Партнерская ссылка', icon: 'mdi-link' };
        case 'UploadImage': return { text: 'Загрузка картинки', icon: 'mdi-file-image-plus' };
        case 'Invite': return { text: 'Пригласить друга', icon: 'mdi-account-multiple-plus' };
        case 'Survey': return { text: 'Опрос', icon: 'mdi-head-question' };
        default: return { text: 'undefined' };
      }
    },

    getTypeDisplay(type) {
      switch (type) {
        case 'SportActivity': return 'Спортивная активность';
        case 'GeoLocation': return 'Геопривязка';
        case 'Quiz': return 'Квиз';
        case 'PartnerLink': return 'Партнерская ссылка';
        case 'UploadImage': return 'Загрузка картинки';
        case 'Registration': return 'Регистрация на забег';
        case 'Participation': return 'Участие в событии';
        case 'Invite': return 'Пригласить друга';
        case 'Survey': return 'Опрос';
        default: return undefined;
      }
    }
  },
}
</script>
