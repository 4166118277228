<template>
  <EditorDialog
    title="Работа"
    :max-width="800"
    :save-func="save"
    @closed="localClose"
  >
    <v-form ref="formTask" v-model="valid" >
      <v-row>
        <v-col>
          <v-select
            v-model="model.type"
            label="Тип"
            item-text="name"
            item-value="key"
            :items="exerciseTypes"
            :disabled="!!objectId"
            hide-details="auto"
            @change="changeType"
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            label="Порядок"
            v-model="model.order"
            :rules="validation.order"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="px-2" v-if="model.type === 'Survey'">
        <v-alert
          border="left"
          dense
          outlined
          color="#2196F3"
        >
        Опрос – тут могут быть любые вопросы: открытые, закрытые, обязательные и нет. главное условие – без наличия правильного или неправильного ответа
        </v-alert>
      </v-row>
      <v-row class="px-2" v-if="model.type === 'Quiz'">
        <v-alert
          border="left"
          dense
          outlined
          color="#2196F3"
        >
        Тест – в этой работе могут присутствовать <b>только</b> вопросы с правильными и неправильными ответами.
        </v-alert>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Название работы"
            v-model="model.title"
            :rules="validation.title"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>

      <div v-if="model.type === 'SportActivity'">
        <v-row>
          <v-col>
            <v-text-field
              :label="labelReward"
              v-model="model.reward"
              :rules="validation.reward"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Максимальное вознаграждение"
              v-model="model.maxReward"
              :rules="validation.maxReward"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              v-model="model.settings.Discipline"
              label="Дисциплина"
              :items="exerciseDisciplines"
              item-text="name"
              item-value="type"
            >
          </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Мин дистанция"
              v-model="model.settings.MinDistance"
              :rules="validation.settingsMinDistance"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Макс дистанция"
              v-model="model.settings.MaxDistance"
              :rules="validation.settingsMaxDistance"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Мин темп"
              v-model="model.settings.MinPace"
              :rules="validation.settingsMinPace"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Макс темп"
              v-model="model.settings.MaxPace"
              :rules="validation.pace"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Мин время (сек)"
              v-model="model.settings.MinTimeInSeconds"
              :rules="validation.time"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Макс время (сек)"
              hide-details="auto"
              v-model="model.settings.MaxTimeInSeconds"
              :rules="validation.time"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <div v-if="model.type === 'GeoLocation'">
        <v-row>
          <v-col>
            <v-text-field
              label="Вознаграждение за работу"
              v-model="model.reward"
              :rules="validation.reward"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Радиус приема чекина, м"
              v-model="model.settings.Radius"
              :rules="validation.radius"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col>
            <h4>МЕСТА ДЛЯ ЧЕКИНА</h4>
          </v-col>
        </v-row>

        <v-row v-for="item in model.settings.Locations"
          :key="item.id">
          <v-col>
            <v-text-field
              label="Название места"
              v-model="item.Name"
              :rules="validation.geoLocationField3Key"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Координаты"
              v-model="item.value"
              :rules="validation.geoLocationField3Value"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-icon @click="deleteGeoLocationPoint(item)">mdi-delete</v-icon>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col>
            <v-btn @click="addGeoLocationPoint()" class="mr-3"><v-icon>mdi-plus-circle</v-icon> ДОБАВИТЬ МЕСТО</v-btn>
            <v-btn @click="importGeoLocation()"><v-icon>mdi-download</v-icon> ИМПОРТИРОВАТЬ</v-btn>
          </v-col>
        </v-row>
      </div>

      <div v-if="model.type === 'Quiz'">
        <v-row>
          <v-col>
            <v-text-field
              label="Номер квиза"
              v-model="model.settings.QuizId"
              :rules="validation.quizeId"
              hide-details="auto"
          ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Баллов за правильный ответ"
              v-model="model.reward"
              :rules="validation.reward"
              hide-details="auto"
          ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Количество вопросов"
              v-model="model.settings.QuizQuestionsQty"
              :rules="validation.quizeQuestionsQty"
              hide-details="auto"
          ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div v-if="model.type === 'Survey'">
        <v-row>
          <v-col>
            <v-text-field
              label="Номер квиза"
              v-model="model.settings.QuizId"
              :rules="validation.quizeId"
              hide-details="auto"
          ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Баллов за правильный ответ"
              v-model="model.reward"
              :rules="validation.reward"
              hide-details="auto"
          ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Количество вопросов"
              v-model="model.settings.SurveyQuestionsQty"
              :rules="validation.quizeQuestionsQty"
              hide-details="auto"
          ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <div v-if="model.type === 'PartnerLink'">
        <v-row>
          <v-col>
            <v-text-field
              label="Ссылка на сайт\страницу партнера"
              v-model="model.settings.PartnerLink"
              :rules="validation.wbLinkPartner"
              hide-details="auto"
          ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Ссылка на поощрение в маркете (необязательно)"
              v-model="model.settings.PromocodeLink"
              :rules="validation.wbLinkPromocode"
              hide-details="auto"
          ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Вознаграждение за работу"
              v-model="model.reward"
              :rules="validation.reward"
              hide-details="auto"
          ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <div v-if="model.type === 'UploadImage'">
        <v-row>
          <v-col>
            <v-text-field
              label="Что прислать"
              v-model="fbWhats"
              hide-details="auto"
              :disabled="true"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Вознаграждение за работу"
              v-model="model.reward"
              :rules="validation.reward"
              hide-details="auto"
          ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Время на «проверку», часов"
              v-model="model.settings.CheckTime"
              :rules="validation.checkTime"
              hide-details="auto"
          ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div v-if="model.type === 'Registration'">
        <v-row>
          <v-col>
            <v-text-field
              label="Ссылка на страницу регистрации"
              v-model="model.settings.Link"
              :rules="validation.link"
              hide-details="auto"
          ></v-text-field>
          </v-col>
        </v-row>
        <p class="mt-4 mb-2 font-weight-bold">Событие</p>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="EventId"
              v-model="model.settings.Events[0].EventId"
              :rules="validation.eventId"
              hide-details="auto"
              dense
          ></v-text-field>
          </v-col>
          <v-col cols="12" class="d-flex align-center">
            <v-text-field
              v-model="model.settings.Events[0].Races"
              label="RaceId"
              :rules="validation.races"
              hide-details="auto"
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Вознаграждение за работу"
              v-model="model.reward"
              :rules="validation.reward"
              hide-details="auto"
          ></v-text-field>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="model.settings.Disciplines"
              label="Дисциплина"
              :items="exerciseDisciplinesRegister"
              item-text="name"
              item-value="type"
              multiple
            >
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0">{{ item.name }}, </span>
              <span v-if="index === 1">{{ item.name }}</span>
              <span
                v-if="index === 2"
                class="grey--text text-caption"
              >
                ( +{{ model.settings.Disciplines.length - 2 }} дисциплина(ы))
              </span>
            </template>
          </v-autocomplete>
          </v-col>
          <!-- <v-col>
            <v-text-field
              label="Максимальное вознаграждение"
              v-model="model.maxReward"
              :rules="validation.settingsMinPace(model.reward, model.maxReward)"
              hide-details="auto"
            ></v-text-field>
          </v-col> -->
        </v-row>
      </div>
      <div v-if="model.type === 'Participation'">
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="EventId"
              v-model="model.settings.Events[0].EventId"
              :rules="validation.eventId"
              hide-details="auto"
              dense
          ></v-text-field>
          </v-col>
          <v-col cols="12" class="d-flex align-center">
            <v-text-field
              v-model="model.settings.Events[0].Races"
              label="RaceId"
              :rules="validation.races"
              hide-details="auto"
              dense
            />
          </v-col>
          <!-- <v-col>
            <v-text-field
              label="ID стартов для регистрации (RaceID). пустое поле – любой старта"
              v-model="model.settings.RaceId"
              :rules="validation.raceId"
              hide-details="auto"
          ></v-text-field>
          </v-col> -->
        </v-row>
        <v-row>
          <!-- <v-col cols="6">
            <v-text-field
              label="Минимальная дистанция (м)"
              v-model="model.settings.MinDistance"
              :rules="validation.settingsMinDistance"
              hide-details="auto"
          ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Максимальное время (сек)"
              v-model="model.settings.MinTimeInSeconds"
              :rules="validation.settingsMinPace1"
              hide-details="auto"
            ></v-text-field>
          </v-col> -->
          <v-col cols="12">
            <v-text-field
              label="Вознаграждение за работу"
              v-model="model.reward"
              :rules="validation.settingsMinPace1"
              hide-details="auto"
          ></v-text-field>
          </v-col>
          <!-- <v-col cols="6">
            <v-text-field
              label="Максимальное вознаграждение"
              v-model="model.maxReward"
              :rules="validation.settingsMinPace(model.reward, model.maxReward)"
              hide-details="auto"
            ></v-text-field>
          </v-col> -->
        </v-row>
      </div>
      <div v-if="model.type === 'Invite'">
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Домен МП"
              v-model="model.settings.Link"
              :rules="validation.link"
              hide-details="auto"
            ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Минимальное количество активаций реф.ссылки"
                v-model="model.settings.RegistrationCount"
                :rules="validation.raceId"
                hide-details="auto"
            ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Вознаграждение за 1 активацию"
                v-model="model.reward"
                :rules="validation.settingsMinPace1"
                hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Максимальное вознаграждение"
              v-model="model.maxReward"
              :rules="validation.maxReward"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </EditorDialog>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import editorDialogMixin from '@/global-components/dialog/mixins';
import { uuid } from "vue-uuid";

export default {
  name: 'ExerciseTaskEditorDialog',
  mixins: [editorDialogMixin],

  props: {
    object: {
      type: Object,
      default: () => {
        return {
          title: null,
          type: 'SportActivity',
          order: 1,
          reward: null,
          maxReward: null,
          settings: {
            Disciplines: [],
            Events: [
              {
                EventId: "",
                Races: []
              }
            ],
            Discipline: "Running",
            MinDistance: "10000",
            MaxDistance: "50000",
            MinPace: "510",
            MaxPace: "180",
            MinTimeInSeconds: null,
            MaxTimeInSeconds: null,
            Radius: null,
            Locations: [{ Name: null, value: null, Latitude: 0, Longitude: 0 }],
            link: null,
            RaceId: null,
            RegistrationCount: null,
          },
        }
      }
    },    
    exerciseType: undefined,
    saveFunc: Function,
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
    
  },

  data: () => ({
    valid: true,
    exerciseDisciplines: [
      {type: 'Running', name: 'Бег'},
      {type: 'Walking', name: 'Ходьба'},
      {type: 'Cycling', name: 'Велосипед'},
      {type: 'Swimming', name: 'Плавание'},
      {type: 'Skiing', name: 'Лыжи'},
    ],
    exerciseDisciplinesRegister: [
      {type: 'acrobat_rnr', name: 'Акробатический рок-н-ролл'},
      {type: 'aquathlon', name: 'Акватлон'},
      {type: 'athletics', name: 'Легкая атлетика'},
      {type: 'athletics-quad', name: 'Легкоатлетическое четырехборье'},
      {type: 'backetball-team', name: 'Баскетбол (командная)'},
      {type: 'backetball', name: 'Баскетбол'},
      {type: 'badminton', name: 'Бадминтон'},
      {type: 'beach-tenn', name: 'Пляжный теннис'},
      {type: 'beach-tenn-team', name: 'Пляжный теннис (командная)'},
      {type: 'beach-voll', name: 'Пляжный волейбол'},
      {type: 'beach-voll-team', name: 'Пляжный волейбол (командная)'},
      {type: 'biathlon', name: 'Двоеборье(плавание и бег)'},
      {type: 'biathlon-relay', name: 'Двоеборье(плавание и бег - эстафета)'},
      {type: 'boxing', name: 'Бокс'},
      {type: 'chess', name: 'Шахматы'},
      {type: 'curling', name: 'Кёрлинг'},
      {type: 'cycling', name: 'Велосипедный спорт'},
      {type: 'cycling_team', name: 'Велосипедный спорт (командная)'},
      {type: 'darts', name: 'Дартс'},
      {type: 'duathlon', name: 'Дуатлон'},
      {type: 'duathlon-relay', name: 'Дуатлон(эстафета)'},
      {type: 'equestrian-touri', name: 'Конный туризм'},
      {type: 'football-youth', name: 'Футбол'},
      {type: 'goalball', name: 'Голбол'},
      {type: 'golf', name: 'Гольф'},
      {type: 'gto', name: 'ГТО'},
      {type: 'gymnastics', name: 'Спортивная гимнастика'},
      {type: 'handball', name: 'Гандбол'},
      {type: 'hockey', name: 'Хоккей'},
      {type: 'jiujitsu', name: 'Джиу-джитсу'},
      {type: 'judo', name: 'Дзюдо'},
      {type: 'm-football', name: 'Мини-футбол. футзал'},
      {type: 'm-football-team', name: 'Мини-футбол. футзал (командная)'},
      {type: 'mma', name: 'ММА'},
      {type: 'mountaineering', name: 'Альпинизм'},
      {type: 'obstacleracing', name: 'Гонка с препятствиями'},
      {type: 'orienteering', name: 'Спортивное ориентирование'},
      {type: 'other', name: 'Другое'},
      {type: 'para_sports', name: 'Виды спорта для лиц с ограниченными возможностями'},
      {type: 'r_gymnastics', name: 'Художественная гимнастика'},
      {type: 'rock-climbing', name: 'Скалолазание'},
      {type: 'rowing', name: 'Гребля'},
      {type: 'rowing-relay', name: 'Гребля (эстафета)'},
      {type: 'run', name: 'Бег'},
      {type: 'run-relay', name: 'Бег(эстафета)'},
      {type: 'sailing', name: 'Парусный спорт'},
      {type: 'sailing-team', name: 'Парусный спорт (командная)'},
      {type: 'sambo', name: 'Самбо'},
      {type: 'ski-biathlon', name: 'Лыжный биатлон'},
      {type: 'ski-race', name: 'Лыжные гонки'},
      {type: 'ski-race-relay', name: 'Лыжные гонки (эстафета)'},
      {type: 'skiing', name: 'Горные лыжи'},
      {type: 'sledge-sports', name: 'Ездовой спорт'},
      {type: 'snowboard', name: 'Сноуборд'},
      {type: 'speedskating', name: 'Спидскейтинг'},
      {type: 'sup', name: 'Гребля на доске стоя'},
      {type: 'sup-relay', name: 'Гребля на доске стоя (эстафета)'},
      {type: 'swimming', name: 'Плавание'},
      {type: 'swimming-team', name: 'Плавание (эстафета)'},
      {type: 'swimrun', name: 'SWIMRUN'},
      {type: 'table-tenn', name: 'Настольный теннис'},
      {type: 'table-tenn-team', name: 'Настольный теннис (командная)'},
      {type: 'team-relay', name: 'Команда'},
      {type: 'tennis', name: 'Теннис'},
      {type: 'tennis-team', name: 'Теннис (командная)'},
      {type: 'trail', name: 'Бег (трейл)'},
      {type: 'triathlon', name: 'Триатлон'},
      {type: 'triathlon-relay', name: 'Триатлон(эстафета)'},
      {type: 'volleball', name: 'Волейбол'},
      {type: 'volleball-team', name: 'Волейбол (командная)'},
      {type: 'walk', name: 'Ходьба'},
      {type: 'weightlifting', name: 'Тяжелая атлетика'},
      {type: 'wrestling', name: 'Спортивная борьба'},
    ],
    fbWhats: 'File',
    validation: {
      title: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 256) || 'Не более 256 символов.',
      ],
      type: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 16) || 'Не более 16 символов.',
      ],
      order: [
        value => !!value || 'Обязательное поле.',
        value => (value && Number.isInteger(value * 1)) || 'Требуется целое число.',
        value => (value && value > 0 && value < 11) || 'Требуется целое число от 1 до 10.',
      ],
      reward: [
        value => !!value || 'Обязательное поле.',
        value => (value && !isNaN(parseFloat(value))) || 'Требуется число.',
        value => (value && value > 0) || 'Требуется число больше 0.',
      ],
      maxReward: [
        value => !!value || 'Обязательное поле.',
        value => (value && !isNaN(parseFloat(value))) || 'Требуется число.',
        value => (value && value > 0) || 'Требуется число больше 0.',
      ],
      radius: [
        value => !!value || 'Обязательное поле.',
        value => !value || /^\d+?$/.test(value) || 'Значение должно быть целым числом.',
      ],
      pace: [
        value => !value || /^\d+?$/.test(value) || 'Значение должно быть целым числом.',
      ],
      time: [
        value => !value || /^\d+?$/.test(value) || 'Значение должно быть целым числом.',
      ],
      geoLocationField3Key: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 256) || 'Не более 256 символов.',
      ],
      geoLocationField3Value: [
        value => !!value || 'Обязательное поле.',
        value => !value || /^-?\d{1,3}\.\d{1,6}, -?\d{1,3}\.\d{1,6}$/.test(value) || 'Формат: 55.715262, 37.595075.'
      ],
      races: [
      value => value === null || value === ''|| /^([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})(,\s*[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})*$/.test(value) || 'Формат: GUID через запятую.'
      ],
      settingsDiscipline: [
        value => !!value || 'Обязательное поле.',
      ],
      settingsMinDistance: [
        value => !!value || 'Обязательное поле.',
        value => !value || /^\d+?$/.test(value) || 'Значение должно быть целым числом.',
      ],
      settingsMaxDistance: [
        value => !!value || 'Обязательное поле.',
        value => !value || /^\d+?$/.test(value) || 'Значение должно быть целым числом.',
      ],
      settingsMinPace1: [
        value => !!value || 'Обязательное поле.',
        value => (value && !isNaN(parseInt(value))) || 'Требуется целое число.',
      ],
      settingsMaxPace: [
        value => !!value || 'Обязательное поле.',
        value => (value && !isNaN(parseInt(value))) || 'Требуется целое число.',
      ],
      quizeId: [
        value => !!value || 'Обязательное поле.',
        value => (value && !isNaN(parseInt(value))) || 'Требуется целое число.',
      ],
      quizeQuestionsQty: [
        value => !!value || 'Обязательное поле.',
        value => (value && !isNaN(parseInt(value))) || 'Требуется целое число.',
      ],
      wbLinkPartner: [
        value => !!value || 'Обязательное поле.',
      ],
      checkTime: [
        value => !!value || 'Обязательное поле.',
        value => (value && !isNaN(parseInt(value))) || 'Требуется целое число.',
      ],
      fbWhat: [
        value => !!value || 'Обязательное поле.',
      ],
      link: [
        value => !!value || 'Обязательное поле.',
        value => !value || /https?:\/\//.test(value) || 'Формат: http:// или https://.',
      ],
      eventId: [
        value => !!value || 'Обязательное поле.',
        value => !value || /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(value) || 'Формат: GUID.'
      ],
      settingsMinTimeInSecondsRunles(maxValue) {
        return [
          value => !!value || 'Обязательное поле.',
          value => /^\d*?$/.test(value) || 'Значение должно отсутствовать или быть целым числом.',
          value => !value || !maxValue || parseInt(value, 10) <= parseInt(maxValue) || 'Минимальное время не может быть больше максимального'
        ];
      },
      settingsMaxTimeInSecondsRunles(minValue) {
        const rules = [
          value => /^\d*?$/.test(value) || 'Значение должно отсутствовать или быть целым числом.',
          value => !value || !minValue || parseInt(value, 10) >= parseInt(minValue) || 'Максимальное время не может быть меньше минимального'
        ];
        return rules;
      },
      minPaceRules(minValue) {
        return [
          value => !!value || 'Required field.',
          value => !value || /^\d*?$/.test(value) || 'Value should be an integer.',
          value => !value || !minValue || parseInt(value, 10) >= parseInt(minValue) || 'Maximum reward cannot be less than the minimum'
        ];
      },
    },
  }),

  computed: {
    raceValues() {
      return this.model.settings.Events[0].Races.map(race => race.value);
    },
    labelReward: function() {
      if (this.model && this.model.settings) {
        const d = this.model.settings.Discipline
        if (d === 'Swimming') return 'Вознаграждение за 100м';
        else return "Вознаграждение за км";
      }
      return "Вознаграждение";
    },
    exerciseTypes: function() {
      const r = [
        {
          key: 'SportActivity',
          name: 'Спортивная активность'
        },
        {
          key: 'GeoLocation',
          name: 'Геопривязка'
        },
        {
          key: 'Quiz',
          name: 'Тест (с правильными ответами)',
          disabled: this.exerciseType !== 'Disposable'
        },
        {
          key: 'PartnerLink',
          name: 'Партнерская ссылка'
        },
        {
          key: 'UploadImage',
          name: 'Загрузка картинки'
        },
        {
          key: 'Registration',
          name: 'Регистрация на забег'
        },
        {
          key: 'Participation',
          name: 'Участие в событии'
        },
        {
          key: 'Invite',
          name: 'Пригласить друга'
        },
        {
          key: 'Survey',
          name: 'Опрос (без «правильных» ответов)',
          disabled: this.exerciseType !== 'Disposable'
        },
      ];

      return r;
    }
  },

  methods: {

    changeType() {
      this.$refs.formTask.resetValidation();
    },

    addEventRaceId() {
      this.model.settings.Events[0].Races.push();
    },

    delEventRaceId() {
      this.model.settings.Events[0].Races.pop();
    },
    addGeoLocationPoint() {
      this.model.settings.Locations.push({ id: uuid.v4(), Name: null, value: null });
    },

    deleteGeoLocationPoint(item) {
      this.model.settings.Locations.splice(this.model.settings.Locations.indexOf(item), 1);
    },

    importGeoLocation() {
      // TODO:
      console.log('Import Geo Activity');
    },

    async save() {
      this.saving = true;
      this.valid = await this.$refs.formTask.validate();

      if (!this.valid)
        return;

      this.saveFunc(this.model);
    },

    localClose() {
      const saving = this.saving;
      this.saving = false;

      if (saving && !this.valid)
        return;

      this.close();
    },
  },
}
</script>
