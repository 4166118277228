<template>
  <div class="pa-6">
    <v-form ref="form" lazy-validation v-model="valid">
      <v-row>
        <v-col cols="12">
          <p class="headline">Верхняя секция</p>
        </v-col>
        <v-col cols="7">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="modelTop.title"
                :rules="validation.title"
                label="Заголовок МП"
              />
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="modelTop.date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    label="Сроки проведения МП (необязательно)"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="clearDateRange"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="modelTop.date"
                  range
                  label="Сроки проведения МП (необязательно)"
                  locale="ru"
                  :first-day-of-week="1"
                >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Отмена
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(modelTop.date)"
                >
                  OK
                </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="modelTop.limitation"
                label="Ограничение МП (необязательно)"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="modelTop.textButton"
                :rules="validation.textButton"
                label="Текст кнопки"
              />
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="modelTop.invertColor"
                label="Инвертировать цвет текста"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5">
          <div :class="showMessage || showMessage1  ? 'active' : ''">
            <v-row>
              <v-col>
                <p class="label" :class="descriptionLength1 ? 'active' : ''">Короткое описание</p>
              </v-col>
            </v-row>
            <vue-editor ref="editor" :editorToolbar="customToolbar" v-model="modelTop.description" @input="descriptionLength"/>
            <p v-if="showMessage" class="description__error">Обязательное поле.</p>
            <p v-if="showMessage1" class="description__error">Максимальное количесто символов {{ limitDscription }}</p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="d-flex">
            <upload-images
              @fileInputClick="clickImage"
              @changeFile="changeTopSectionDesktopImage"
              :imageSelected="imageTopSectionDesktopSelected"
              class="mr-4"
              width="364"
              height="200"
              title="Десктопное изображение"
              accept=".png, .jpg, .jpeg"
              imageSize="2048х1152"
            />
            <upload-images
              @fileInputClick="clickImage"
              @changeFile="changeTopSectionMobileImage"
              :imageSelected="imageTopSectionMobileSelected"
              width="364"
              height="200"
              title="Мобильное изображение"
              accept=".png, .jpg, .jpeg"
              imageSize="720х400"
            />
          </div>
        </v-col>
      </v-row>
      <v-divider class="my-8"/>
      <v-row>
        <v-col cols="12">
          <p class="headline">Нижняя секция</p>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="modelBottom.title"
            :rules="validation.title2"
            label="Заголовок"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="modelBottom.subTitle"
            :rules="validation.subTitle"
            label="Подзаголовок"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="modelBottom.limitation"
            label="Ограничение"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="modelBottom.textButton"
            :rules="validation.textButton2"
            label="Текст кнопки"
          />
        </v-col>
        <v-col cols="4">
          <v-checkbox
            v-model="modelBottom.invertColor"
            label="Инвертировать цвет текста"
          />
        </v-col>
        <v-col cols="12">
          <div class="d-flex">
            <upload-images
              @fileInputClick="clickImage"
              @changeFile="changeBottomSectionDesktopImage"
              :imageSelected="imageBottomSectionDesktopSelected"
              class="mr-4"
              width="364"
              height="200"
              title="Десктопное изображение"
              accept=".png, .jpg, .jpeg"
              imageSize="2048х1152"
            />
            <upload-images
              @fileInputClick="clickImage"
              @changeFile="changeBottomSectionMobileImage"
              :imageSelected="imageBottomSectionMobileSelected"
              width="364"
              height="200"
              title="Мобильное изображение"
              accept=".png, .jpg, .jpeg"
              imageSize="720х400"
            />
          </div>
        </v-col>
      </v-row>
      <v-divider class="my-8"/>
      <v-row>
        <v-col cols="12">
          <p class="headline">Страница "о программе"</p>
        </v-col>
        <v-col cols="12">
          <div class="d-flex">
            <upload-images
              @fileInputClick="clickImage"
              @changeFile="changeAboutPageDesktopImage"
              :imageSelected="imageAboutPageDesktopSelected"
              class="mr-4"
              width="364"
              height="200"
              title="Десктопное изображение"
              accept=".png, .jpg, .jpeg"
              imageSize="2048х1152"
            />
            <upload-images
              @fileInputClick="clickImage"
              @changeFile="changeAboutPageMobileImage"
              :imageSelected="imageAboutPageMobileSelected"
              width="364"
              height="200"
              title="Мобильное изображение"
              accept=".png, .jpg, .jpeg"
              imageSize="720х400"
            />
          </div>
        </v-col>
      </v-row>
      <v-divider class="my-8"/>
      <v-row>
        <v-col cols="12">
          <p class="headline">Страница авторизации</p>
        </v-col>
        <v-col cols="12">
          <div class="d-flex">
            <upload-images
              @fileInputClick="clickImage"
              @changeFile="changeAuthorizationPageDesktopImage"
              :imageSelected="imageAuthorizationSectionDesktopSelected"
              class="mr-4"
              width="364"
              height="200"
              title="Десктопное изображение"
              accept=".png, .jpg, .jpeg"
              imageSize="2048х1152"
            />
          </div>
        </v-col>
      </v-row>
      <v-divider class="my-8"/>
      <v-row>
        <v-col cols="12">
          <v-btn @click="changeIntroSection" large class="mr-4" :disabled="!valid || descriptionLength1 || lengthDescrition > limitDscription">
            <v-icon left>mdi-content-save</v-icon>
              Сохранить
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <notifications :duration="5000" position="bottom right"/>
  </div>
</template>

<script>
  import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
  import scountIntroApiInitializer from '@/api/scountIntroApi';
  import UploadImages from '@/components/uploadImages'
  import { VueEditor } from "vue2-editor";
  import { uuid } from 'vue-uuid';
  export default {
    name: 'IntroView',
    components: { UploadImages, VueEditor },
    data() {
      return {
        dataInfo: {},
        introSectionId: null,
        modelTop: {
          title: '',
          description: '',
          date: [],
          limitation: '',
          textButton: '',
          invertColor: false
        },
        modelBottom: {
          title: '',
          subTitle: '',
          limitation: '',
          textButton: '',
          invertColor: false
        },
        limitDscription: 400,
        lengthDescrition: 0,
        showMessage: false,
        showMessage1: false,
        customToolbar: [
          ["bold",{ list: "ordered"}, {list: "bullet" }, "link",],
        ],
        valid: true,
        validation: {
          title: [
            value => !!value || 'Обязательное поле.',
          ],
          title2: [
            value => !!value || 'Обязательное поле.',
          ],
          subTitle: [
            value => !!value || 'Обязательное поле.',
          ],
          textButton: [
            value => !!value || 'Обязательное поле.',
          ],
          textButton2: [
            value => !!value || 'Обязательное поле.',
          ]
        },
        imageTopSectionDesktopSelected: null,
        imageTopSectionDesktopFile: null,
        imageTopSectionMobileSelected: null,
        imageTopSectionMobileFile: null,
        imageBottomSectionDesktopSelected: null,
        imageBottomSectionDesktopFile: null,
        imageBottomSectionMobileSelected: null,
        imageBottomSectionMobileFile: null,
        imageAuthorizationSectionDesktopSelected: null,
        imageAuthorizationSectionDesktopFile: null,
        imageAboutPageDesktopSelected: null,
        imageAboutPageDesktopFile: null,
        imageAboutPageMobileSelected: null,
        imageAboutPageMobileFile: null,
        menu: false
      }
    },
    methods: {
      clearDateRange() {
        this.modelTop.date = []; // Очищаем массив дат
      },
      descriptionLength() {
        const quill = this.$refs.editor.quill
        this.lengthDescrition = quill.getLength()
        this.showMessage = this.modelTop.description.trim() === '';
        this.showMessage1 = this.lengthDescrition > this.limitDscription;
        if (this.lengthDescrition > this.limitDscription) {
          quill.deleteText(this.limitDscription, this.lengthDescrition);
        }
      },
      changeTopSectionDesktopImage(imageFile, file) {
        if(imageFile) {
          const ImageKey = uuid.v4()
          this.imageTopSectionDesktopSelected = imageFile
          this.imageTopSectionDesktopFile = file
          this.scountIntroApi.uploadTopSectionDesktopImage(this.introSectionId, ImageKey, file)
            .then(() => {
              this.fetchDataInfo()
              this.$notify({
                type: 'success',
                title: 'Успешно',
                text: 'Десктопное изображение верхней секции обновлено'
              })
            })
        }
      },
      changeTopSectionMobileImage(imageFile, file) {
        if(imageFile) {
          const ImageKey = uuid.v4()
          this.imageTopSectionMobileSelected = imageFile
          this.imageTopSectionMobileFile = file
          this.scountIntroApi.uploadTopSectionMobileImage(this.introSectionId, ImageKey, file)
            .then(() => {
              this.fetchDataInfo()
              this.$notify({
                type: 'success',
                title: 'Успешно',
                text: 'Мобильное изображение верхней секции обновлено'
              })
            })
        }
      },
      changeBottomSectionDesktopImage(imageFile, file) {
        if(imageFile) {
          const ImageKey = uuid.v4()
          this.imageBottomSectionDesktopSelected = imageFile
          this.imageBottomSectionDesktopFile = file
          this.scountIntroApi.uploadBottomSectionDesktopImage(this.introSectionId, ImageKey, file)
            .then(() => {
              this.fetchDataInfo()
              this.$notify({
                type: 'success',
                title: 'Успешно',
                text: 'Десктопное изображение нижней секции обновлено'
              })
            })
        }
      },
      changeBottomSectionMobileImage(imageFile, file) {
        if(imageFile) {
          const ImageKey = uuid.v4()
          this.imageBottomSectionMobileSelected = imageFile
          this.imageBottomSectionMobileFile = file
          this.scountIntroApi.uploadBottomSectionMobileImage(this.introSectionId, ImageKey, file)
            .then(() => {
              this.fetchDataInfo()
              this.$notify({
                type: 'success',
                title: 'Успешно',
                text: 'Мобильное изображение нижней секции обновлено'
              })
            })
        }
      },
      changeAuthorizationPageDesktopImage(imageFile, file) {
        if(imageFile) {
          const ImageKey = uuid.v4()
          this.imageAuthorizationSectionDesktopSelected = imageFile
          this.imageAuthorizationSectionDesktopFile = file
          this.scountIntroApi.uploadAutorizationImage(this.introSectionId, ImageKey, file)
            .then(() => {
              this.fetchDataInfo()
              this.$notify({
                type: 'success',
                title: 'Успешно',
                text: 'Десктопное изображение страницы авторизации обновлено'
              })
            })
        }
      },
      changeAboutPageDesktopImage(imageFile, file) {
        if(imageFile) {
          const ImageKey = uuid.v4()
          this.imageAboutPageDesktopSelected = imageFile
          this.imageAboutPageDesktopFile = file
          this.scountIntroApi.uploadAboutPageDesktopImage(this.introSectionId, ImageKey, file)
            .then(() => {
              this.fetchDataInfo()
              this.$notify({
                type: 'success',
                title: 'Успешно',
                text: 'Десктопное изображение страницы "о программе" обновлено'
              })
            })
        }
      },
      changeAboutPageMobileImage(imageFile, file) {
        if(imageFile) {
          const ImageKey = uuid.v4()
          this.imageAboutPageMobileSelected = imageFile
          this.imageAboutPageMobileFile = file
          this.scountIntroApi.uploadAboutPageMobileImage(this.introSectionId, ImageKey, file)
            .then(() => {
              this.fetchDataInfo()
              this.$notify({
                type: 'success',
                title: 'Успешно',
                text: 'Мобильное изображение страницы "о программе" обновлено'
              })
            })
        }
      },
      clickImage(fileUploadId) {
        document.getElementById(fileUploadId).click();
      },
      fetchDataInfo() {
        this.scountIntroApi.getIntroSection().then((response) => {
          this.dataInfo = response.data
          this.introSectionId = this.dataInfo.introSectionId
          this.modelTop.title = this.dataInfo.topSectionTitle
          this.modelTop.description = this.dataInfo.topSectionShortDescription
          this.modelTop.date = this.dataInfo.topSectionDates.split(' - ')
          this.modelTop.limitation = this.dataInfo.topSectionRestrictions
          this.modelTop.textButton = this.dataInfo.topSectionButtonText
          this.modelTop.invertColor = this.dataInfo.topSectionButtonInversion
          this.modelBottom.title = this.dataInfo.bottomSectionTitle
          this.modelBottom.subTitle = this.dataInfo.bottomSectionSubTitle
          this.modelBottom.limitation = this.dataInfo.bottomSectionRestrictions
          this.modelBottom.textButton = this.dataInfo.bottomSectionShortDescription
          this.modelBottom.invertColor = this.dataInfo.bottomSectionButtonInversion
          this.imageTopSectionDesktopSelected = this.dataInfo.topSectionDesktopImageUrl
          this.imageTopSectionMobileSelected = this.dataInfo.topSectionMobileImageUrl
          this.imageBottomSectionDesktopSelected = this.dataInfo.bottomSectionDesktopImageUrl
          this.imageBottomSectionMobileSelected = this.dataInfo.bottomSectionMobileImageUrl
          this.imageAuthorizationSectionDesktopSelected = this.dataInfo.autorizationDesktopImageUrl
          this.imageAboutPageDesktopSelected = this.dataInfo.aboutPageDesktopImageUrl
          this.imageAboutPageMobileSelected = this.dataInfo.aboutPageMobileImageUrl
        })
      },
      changeIntroSection() {
        this.scountIntroApi.changeIntroSection(this.introSectionId, {
          topSectionTitle: this.modelTop.title,
          topSectionShortDescription: this.modelTop.description || ' ',
          topSectionDates: this.modelTop.date[0] ? this.modelTop.date[0] + (this.modelTop.date[1] ? ' - ' + this.modelTop.date[1] : '') : '',
          topSectionRestrictions: this.modelTop.limitation,
          topSectionButtonText: this.modelTop.textButton,
          topSectionButtonInversion: this.modelTop.invertColor,
          bottomSectionTitle: this.modelBottom.title,
          bottomSectionSubTitle: this.modelBottom.subTitle,
          bottomSectionShortDescription: this.modelBottom.textButton,
          bottomSectionRestrictions: this.modelBottom.limitation,
          bottomSectionButtonInversion: this.modelBottom.invertColor,
        }).then(() => {
          this.fetchDataInfo();
          this.$notify({
            type: 'success',
            title: 'Успешно',
            text: 'Данные обновлены'
          })
        })
      }
    },

    setup() {
      const marketingProgramStore = useMarketingProgramStore()
      return { marketingProgramStore }
    },
    computed: {
      mp() {
        if (this.marketingProgramStore.isInitialized === false)
          return null;
        return this.marketingProgramStore;
      },

      scountIntroApi() {
        if (!this.mp)
          return null;
        return scountIntroApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },
      descriptionLength1() {
        return this.modelTop.description.trim() === '' ? true : false;
      },
      dateRangeText () {
        return this.modelTop.date.join(' ~ ')
      },
    },

    mounted() {
      this.fetchDataInfo();
    }
  }
</script>

<style lang="scss" scoped>
 ::v-deep .quillWrapper > .ql-container {
    border-color: rgba(0, 0, 0, 0.42);
  }
  ::v-deep .ql-toolbar.ql-snow {
    border-color: rgba(0, 0, 0, 0.42);
  }
  ::v-deep .ql-tooltip.ql-editing {
    left: 0 !important;
  }
  .active {
    .label {
      color: #ff5252;
    }
    ::v-deep .quillWrapper > .ql-container {
    border-color: #ff5252;
    }
    ::v-deep .ql-toolbar.ql-snow {
      border-color: #ff5252;

    }
    ::v-deep .ql-snow .ql-stroke{
        stroke: #ff5252;
      }
    ::v-deep .ql-fill{
        fill: #ff5252;
      }
  }
</style>