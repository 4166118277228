<template>
    <div>
      <div class="mb-6">
        <h2 class="cmn__h1 mb-5">Активное ядро аудитории</h2>
        <v-row>
          <v-col>
            <v-text-field
              label="Начало отчетного периода"
              v-model="activeCoreParams.startDate"
              :rules="validation.date"
              hide-details="auto"
              append-outer-icon="mdi-calendar-blank"
              placeholder="ДД.ММ.ГГГГ"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Конец отчетного периода"
              v-model="activeCoreParams.endDate"
              :rules="validation.date"
              hide-details="auto"
              append-outer-icon="mdi-calendar-blank"
              placeholder="ДД.ММ.ГГГГ"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="d-flex align-end">
            <v-btn large @click="activeCoreShow" class="ml-3">Показать</v-btn>
            <v-btn large @click="activeCoreCsv" class="ml-3">
              <v-icon left>
                  mdi-content-save
              </v-icon>
              Скачать в CSV
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <div v-if="isGeneratingReport" class="alert alert-info">
              Идет формирование отчета...
            </div>
            <DataTable 
              v-else
              :key="activeCoreFiltersHash"
              :headers="activeCoreHeaders"
              :items="activeCoreItems"
              :loading="activeCoreUpdating"
              :hide-default-footer="true"
              @update:table-options="activeCoreTableUpdated"
            >
              <template v-slot:[`item.count`]="{item}">
                {{ item.count }}
                <span class="count-added" v-if="item.countAdded">({{ item.countAdded > 0 ? `+${item.countAdded}` : item.countAdded }})</span>
              </template>
            </DataTable>
          </v-col>
        </v-row>
      </div>
    </div>
  </template>

  <script>
  import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
  import scountApiInitializer from '@/api/scountApi';
  import ratingsApiInitializer from '@/api/ratingsApi';
  import Moment from 'moment'
  export default {
    name: 'AnaliticsView',
    setup() {
      const marketingProgramStore = useMarketingProgramStore()
      return { marketingProgramStore }
    },

    data: () => ({
      validation: {
        date: [
          value => !value || /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d$/i.test(value) || 'Некорректная дата. Формат ДД.ММ.ГГГГ',
        ],
      },
      activeCoreHeaders: [
        { value: 'name', text: 'Ядро аудитории', align: 'start', sortable: false },
        { value: 'count', text: '', align: 'start', sortable: false },
      ],
      activeCoreUpdating: false,
      activeCoreParams: {
        startDate: undefined,
        endDate: undefined,
      },
      activeCoreItems: undefined,
      activeCoreFiltersHash: Date.now(),
      activeCoreTableShow: false,
      reportId: null,
      attempts: 0,
      maxAttempts: 5,
      intervalShort: 2000,
      intervalLong: 20000,
      maxDuration: 5 * 60 * 1000,
      startTime: null,
      updateCSV: false,
      isGeneratingReport: false,
    }),

    computed: {
      mp() {
        if (this.marketingProgramStore.isInitialized === false)
          return null;
        return this.marketingProgramStore;
      },
      scountApi() {
        if (!this.mp)
          return null;
        return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },
      ratingsApi() {
        if (!this.mp)
          return null;
        return ratingsApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },
    },

    methods: {
      async checkReportStatus() {
        this.startTime = new Date();
        this.attempts = 0; // Инициализируем количество попыток
        const maxAttempts = 20; // Максимальное количество попыток
        const shortDelay = 5000; // 5 секунд в миллисекундах
        const longDelay = 30000; // 30 секунд в миллисекундах
        const maxDuration = 180000; // 3 минуты в миллисекундах

        while (this.attempts < maxAttempts) { // Проверяем количество попыток
          let delayTime;
          try {
            let response;
            if (!this.updateCSV) {
              response = await this.scountApi.getActiveCoreReport(this.reportId);
              if (response.status === 200) {
                this.activeCoreUpdating = false;
                const d = response.data.common;
                this.activeCoreItems = [
                  { name: 'Выполнили задания', count: d.completedTasks.count },
                  { name: 'Выполнили фоновые и покупали', count: d.completedBackAndBuy.count },
                  { name: 'Начислено баллов', count: d.pointsAccrued.count },
                  { name: 'Потрачено баллов', count: d.pointsSpent.count },
                  { name: 'Покупок', count: d.purchases.count },
                ];
                break; // Выход из цикла, если статус 200
              } else if (response.status === 404) {
                delayTime = this.attempts < 8 ? shortDelay : longDelay; // Задержка при 404
              }
            } else {
              response = await this.scountApi.getActiveCoreReportCsv(this.reportId);
              const fileUrl = window.URL.createObjectURL(response.data);
              const anchor = document.createElement("a");
              anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
              anchor.href = fileUrl;
              anchor.target = "_blank";
              anchor.click();
              anchor.remove();
              if (response.status === 200) {
                this.updateCSV = false;
                break;
              } else if (response.status === 404) {
                this.updateCSV = false;
              }
            }
          } catch (error) {
            console.error('Error while checking report status:', error);
          }
          this.attempts++;
          const elapsedTime = new Date() - this.startTime;
          // Проверяем, достигли ли максимального времени
          if (elapsedTime >= maxDuration) {
            this.isGeneratingReport = false;
            console.log('Max duration reached. Stopping checks.');
            break;
          }
          delayTime = this.attempts < 8 ? shortDelay : longDelay; // Первые 8 попыток - 5 секунд, остальные - 30 секунд
          await this.delay(delayTime);
          // Проверка на максимальное количество попыток
          if (this.attempts >= maxAttempts) {
              this.isGeneratingReport = false;
              console.log('Max attempts reached. Stopping checks.');
              break;
          }
        }
      },
      delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },

      async analiticsShow() {
        this.analiticsTableShow = true;
        this.analiticsFiltersHash = Date.now();
        this.analiticsCommonFiltersHash = Date.now();
      },

      activeCoreShow() {
        this.activeCoreTableShow = true;
        this.activeCoreFiltersHash = Date.now();
      },
      async activeCoreTableUpdated(pagination, sorting, search) {
        await this.getActiveCore(pagination, sorting, search);
      },
      async getActiveCore() {
        if (!this.scountApi) return;
        this.activeCoreUpdating = true;
        try {
          await this.scountApi.generateActiveCore(
            this.activeCoreParams.startDate ? Moment(this.activeCoreParams.startDate, 'DD.MM.YYYY').toDate() : null,
            this.activeCoreParams.endDate ? Moment(this.activeCoreParams.endDate, 'DD.MM.YYYY').toDate() : null,
          )
          .then(response => {
            if (response.status === 200) {
                this.reportId = response.data;
                this.checkReportStatus();
            }
          });
        } finally {
          this.activeCoreUpdating = false;
        }
      },
      async activeCoreCsv() {
        this.updateCSV = true;
        const pagination = { offset: 0, count: 100000 };
        await this.scountApi.generateActiveCore(
          pagination,
          this.activeCoreParams.startDate ? Moment(this.activeCoreParams.startDate, 'DD.MM.YYYY').toDate() : null,
          this.activeCoreParams.endDate ? Moment(this.activeCoreParams.endDate, 'DD.MM.YYYY').toDate() : null,
        ).then(response => {
          if (response.status === 200) {
            this.reportId = response.data;
            this.checkReportStatus();
          }
        });
      },
    },
  }
  </script>