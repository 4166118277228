<template>
    <div>
      <div class="mb-6">
        <h2 class="cmn__h1 mb-5">Новые участники</h2>
        <v-row>
          <v-col>
            <v-text-field
              label="Начало отчетного периода"
              v-model="newParticipantsParams.startDate"
              :rules="validation.date"
              hide-details="auto"
              append-outer-icon="mdi-calendar-blank"
              placeholder="ДД.ММ.ГГГГ"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Конец отчетного периода"
              v-model="newParticipantsParams.endDate"
              :rules="validation.date"
              append-outer-icon="mdi-calendar-blank"
              placeholder="ДД.ММ.ГГГГ"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="d-flex align-end">
            <v-btn large @click="newParticipantsShow" class="ml-3">Показать</v-btn>
            <v-btn large @click="newParticipantsCsv" class="ml-3">
                <v-icon left>
                    mdi-content-save
                </v-icon>
                Скачать в CSV</v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <DataTable v-if="newParticipantsTableShow"
              :key="newParticipantsFiltersHash"
              :headers="newParticipantsHeaders"
              :items="newParticipantsItems"
              :loading="newParticipantsUpdating"
              :server-items-length="newParticipantsTotal"
              @update:table-options="newParticipantsTableUpdated"
            >
              <template v-slot:[`item.lastName`]="{item}">
                <span class="trancate">{{ item.lastName }}</span>
              </template>
              <template v-slot:[`item.firstName`]="{item}">
                <span class="trancate">{{ item.firstName }}</span>
              </template>
              <template v-slot:[`item.registration`]="{item}">
                {{ item.registration | formatDateTimeRus }}
              </template>
              <template v-slot:[`item.organization`]="{item}">
                <span class="trancate">{{ item.organization ? item.organization : '-' }}</span>
              </template>
              <template v-slot:[`item.phone`]="{item}">
                {{ item.phone ? item.phone : '-' }}
              </template>
              <template v-slot:[`item.birthday`]="{item}">
                {{ item.birthday | formatDateRus }}
              </template>
              <template v-slot:[`item.homeAddress`]="{item}">
                {{ item.homeAddress ? item.homeAddress : '-' }}
              </template>
              <template v-slot:[`item.gender`]="{item}">
                {{ item.gender === 'Male' ? 'муж.' : item.gender === 'Female' ? 'жен.' : '-' }}
              </template>
              <template v-slot:[`item.unionMemberTicket`]="{item}">
                {{ item.unionMemberTicket ? item.unionMemberTicket : '-' }}
              </template>
              <template v-slot:[`item.teamName`]="{item}">
                <span class="trancate">{{ item.teamName ? item.teamName : '-' }}</span>
              </template>
            </DataTable>
          </v-col>
        </v-row>
      </div>
    </div>
  </template>

  <script>
  import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
  import scountApiInitializer from '@/api/scountApi';
  import ratingsApiInitializer from '@/api/ratingsApi';
  import Moment from 'moment'

  export default {
    name: 'MembersView',
    setup() {
      const marketingProgramStore = useMarketingProgramStore()
      return { marketingProgramStore }
    },
    data: () => ({
      validation: {
        date: [
          value => !value || /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d$/i.test(value) || 'Некорректная дата. Формат ДД.ММ.ГГГГ',
        ],
      },
      newParticipantsHeaders: [
        { value: 'lastName', text: 'Фамилия', align: 'start', sortable: false, width: 160 },
        { value: 'firstName', text: 'Имя', align: 'start', sortable: false, width: 160 },
        { value: 'emailAddress', text: 'Email', align: 'start', sortable: false, width: 200 },
        { value: 'registration', text: 'Регистрация', align: 'start', sortable: false, width: 180 },
        { value: 'birthday', text: 'Д.рождения', align: 'start', sortable: false },
        { value: 'gender', text: 'Пол', align: 'start', sortable: false },
        { value: 'organization', text: 'Организация', align: 'start', sortable: false, width: 200 },
        { value: 'phone', text: 'Телефон', align: 'start', sortable: false },
        { value: 'homeAddress', text: 'Адрес', align: 'start', sortable: false, width: 200 },
        { value: 'unionMemberTicket', text: 'Номер', align: 'start', sortable: false },
        { value: 'teamName', text: 'Команда', align: 'start', sortable: false, width: 200 },
      ],
      newParticipantsTotal: 0,
      newParticipantsUpdating: false,
      newParticipantsParams: {
        startDate: undefined,
        endDate: undefined,
      },
      newParticipantsItems: [],
      newParticipantsFiltersHash: Date.now(),
      newParticipantsTableShow: false,
      reportId: null,
      updateCSV: false,
    }),

    computed: {
      mp() {
        if (this.marketingProgramStore.isInitialized === false)
          return null;

        return this.marketingProgramStore;
      },

      scountApi() {
        if (!this.mp)
          return null;

        return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },

      ratingsApi() {
        if (!this.mp)
          return null;

        return ratingsApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },
    },

    methods: {
      async checkReportStatus() {
        this.startTime = new Date();
        this.attempts = 0; // Инициализируем количество попыток
        const maxAttempts = 20; // Максимальное количество попыток
        const shortDelay = 5000; // 5 секунд в миллисекундах
        const longDelay = 30000; // 30 секунд в миллисекундах
        const maxDuration = 180000; // 3 минуты в миллисекундах

        while (this.attempts < maxAttempts) { // Проверяем количество попыток
          let delayTime;
          try {
            let response;
            if (!this.updateCSV) {
              response = await this.scountApi.getNewParticipantsReport(this.reportId);
              if (response.status === 200) {
                const { values, total } = response.data;
                this.newParticipantsTotal = total;
                this.newParticipantsItems = values;
                console.log('Report is ready.');
                this.isGeneratingReport = false;
                break; // Выход из цикла, если статус 200
              } else if (response.status === 404) {
                delayTime = this.attempts < 8 ? shortDelay : longDelay; // Задержка при 404
              }
            } else {
              response = await this.scountApi.getNewParticipantsReportCsv(this.reportId);
              const fileUrl = window.URL.createObjectURL(response.data);
              const anchor = document.createElement("a");
              anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
              anchor.href = fileUrl;
              anchor.target = "_blank";
              anchor.click();
              anchor.remove();
              if (response.status === 200) {
                this.updateCSV = false;
                break;
              } else if (response.status === 404) {
                this.updateCSV = false;
              }
            }
          } catch (error) {
            console.error('Error while checking report status:', error);
          }
          this.attempts++;
          const elapsedTime = new Date() - this.startTime;
          // Проверяем, достигли ли максимального времени
          if (elapsedTime >= maxDuration) {
            this.isGeneratingReport = false;
            console.log('Max duration reached. Stopping checks.');
            break;
          }
          delayTime = this.attempts < 8 ? shortDelay : longDelay; // Первые 8 попыток - 5 секунд, остальные - 30 секунд
          await this.delay(delayTime);
          // Проверка на максимальное количество попыток
          if (this.attempts >= maxAttempts) {
              this.isGeneratingReport = false;
              console.log('Max attempts reached. Stopping checks.');
              break;
          }
        }
      },

      delay(ms) {
          return new Promise(resolve => setTimeout(resolve, ms));
      },
      newParticipantsShow() {
        this.newParticipantsTableShow = true;
        this.newParticipantsFiltersHash = Date.now();
      },
      async newParticipantsTableUpdated(pagination, sorting, search) {
        await this.getNewParticipants(pagination, sorting, search);
      },
      async getNewParticipants(pagination, sorting, search) {
        if (!this.scountApi) return;
        this.newParticipantsUpdating = true;
        try {
          await this.scountApi.generateNewParticipants(
            pagination, sorting, search,
            this.newParticipantsParams.startDate ? Moment(this.newParticipantsParams.startDate, 'DD.MM.YYYY').toDate() : null,
            this.newParticipantsParams.endDate ? Moment(this.newParticipantsParams.endDate, 'DD.MM.YYYY').toDate() : null,
          )
          .then(response => {
            if (response.status === 200) {
                this.reportId = response.data;
                this.checkReportStatus();
            }
          });
        } finally {
          this.newParticipantsUpdating = false;
        }
      },

      async newParticipantsCsv(sorting, search) {
        const pagination = { offset: 0, count: 100000 };
        this.updateCSV = true;
        await this.scountApi.generateNewParticipants(
          pagination,
          sorting, 
          search,
          this.newParticipantsParams.startDate ? Moment(this.newParticipantsParams.startDate, 'DD.MM.YYYY').toDate() : null,
          this.newParticipantsParams.endDate ? Moment(this.newParticipantsParams.endDate, 'DD.MM.YYYY').toDate() : null
        ).then(response => {
          if (response.status === 200) {
            this.reportId = response.data;
            this.checkReportStatus();
          }
        });
      },
    }
  }
  </script>