import {initializeApi} from "@/api/base";

function withMarketingProgram(marketingProgramId) {
  const api = initializeApi("/api/quizes-task-manager", {'X-MarketingProgram-Id': marketingProgramId});

  async function getQuizes() {
    const response = await api
      .get(`/api/v1/reports/quizes`, {})
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getShort(pagination, sorting, externalId, dateFrom, dateTo) {
    const response = await api
      .get(`/api/v1/reports/short`, {
        params: {
          ...pagination,
          ...sorting,
          externalId: externalId,
          dateFrom: dateFrom,
          dateTo: dateTo,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getQuizesCsv(externalId, dateFrom, dateTo) {
    const response = await api
      .get(`/api/v1/reports/csv`, {
        responseType: 'blob',
        params: {
          externalId: externalId,
          dateFrom: dateFrom,
          dateTo: dateTo,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data, request: response.request};
      });

    return response;
  }

  return {
    getQuizes,
    getShort,
    getQuizesCsv
  };
}
export default {
  withMarketingProgram,
};