import {initializeApi} from "@/api/base";
// import api from './api';
// const api = initializeApi("/api/market", {});

function withMarketingProgram(marketingProgramId) {
  const api = initializeApi("/api/market", {'X-MarketingProgram-Id': marketingProgramId});

  async function getPromotionsCategories() {
    const response = await api
      .get(`/api/v1/analytics/promotions/categories`, {})
      .then(response => {
        return {status: response.status, data: response.data};
      });
    return response;
  }
  async function getPromotionsTypes() {
    const response = await api
      .get(`/api/v1/analytics/promotions/types`, {})
      .then(response => {
        return {status: response.status, data: response.data};
      });
    return response;
  }
  async function getPromotionsPartners({ creatorId }) {
    const response = await api
      .get(`/api/v1/analytics/promotions/partners`,
      { 
        params: {
          creatorId: creatorId
        },
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });
    return response;
  }

  async function generatePromotionAnalytics(pagination, sorting, dateFrom, dateTo, raffleOnFrom, raffleOnTo, deactivateDateFrom, deactivateDateTo, expiresOnFrom, expiresOnTo, marketingProgramId, type, partner, category, isActive, withoutDeactivateDate, creatorId) {
    const response = await api
      .post(`/api/v1/analytics/promotions/generate`, {},
        {
          params: {
            ...pagination,
            ...sorting,
            dateFrom: dateFrom,
            dateTo: dateTo,
            raffleOnFrom: raffleOnFrom,
            raffleOnTo: raffleOnTo,
            deactivateDateFrom: deactivateDateFrom,
            deactivateDateTo: deactivateDateTo,
            expiresOnFrom: expiresOnFrom,
            expiresOnTo: expiresOnTo,
            marketingProgramId,
            type,
            partner,
            category,
            isActive,
            withoutDeactivateDate,
            creatorId
          }
        }
      )
      .then(response => {
        return {status: response.status, data: response.data};
      });
    return response;
  }

  async function getPromotionHistoriesReport(reportId) {
    const response = await api
    .get(`/api/v1/analytics/promotions/${reportId}`)
    .then(response => {
      return {status: response.status, data: response.data};
    })

  return response;
  }

  async function getPromotionHistoriesCsvReport(reportId) {
    const response = await api
    .get(`/api/v1/analytics/promotions/csv/${reportId}`, {
      responseType: 'blob',
    })
    .then(response => {
      return {status: response.status, data: response.data, request: response.request};
    })
  return response;
  }

  async function generatePurchaseHistory(pagination, sorting, dateFrom, dateTo, marketingProgramId, searchByPromocodeString, promotionId, partnerId, promotionType, searchByEmailString, creatorId) {
    const response = await api
    .post(`/api/v1/purchase-histories/generate`,{}, {
      params: {
        searchByEmailString: searchByEmailString,
        searchByPromocodeString: searchByPromocodeString,
        ...pagination,
        ...sorting,
        dateFrom: dateFrom,
        dateTo: dateTo,
        marketingProgramId,
        promotionId,
        partnerId,
        promotionType,
        creatorId
      }
    })
    .then(response => {
      return {status: response.status, data: response.data};
    });

  return response;
  }

  async function getPurchaseHistoryReport(reportId) {
    const response = await api
    .get(`/api/v1/purchase-histories/${reportId}`)
    .then(response => {
      return {status: response.status, data: response.data};
    })
  return response;
  }
  async function getPurchaseHistoryCsvReport(reportId) {
    const response = await api
    .get(`/api/v1/purchase-histories/csv/${reportId}`, {
      responseType: 'blob',
    })
    .then(response => {
      return {status: response.status, data: response.data, request: response.request};
    })
  return response;
  }

  async function getPromotionHistories(pagination, sorting, dateFrom, dateTo, raffleOnFrom, raffleOnTo, deactivateDateFrom, deactivateDateTo, expiresOnFrom, expiresOnTo, marketingProgramId, type, partner, category, isActive, withoutDeactivateDate, creatorId) {
    const response = await api
      .get(`/api/v1/analytics/promotions`, {
        params: {
          ...pagination,
          ...sorting,
          dateFrom: dateFrom,
          dateTo: dateTo,
          raffleOnFrom: raffleOnFrom,
          raffleOnTo: raffleOnTo,
          deactivateDateFrom: deactivateDateFrom,
          deactivateDateTo: deactivateDateTo,
          expiresOnFrom: expiresOnFrom,
          expiresOnTo: expiresOnTo,
          marketingProgramId,
          type,
          partner,
          category,
          isActive,
          withoutDeactivateDate,
          creatorId
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });
      console.log(response);
    return response;
  }
  async function getPurchasePromotionsCsv(dateFrom, dateTo, raffleOnFrom, raffleOnTo, deactivateDateFrom, deactivateDateTo, expiresOnFrom, expiresOnTo, marketingProgramId, type, partner, category, isActive, withoutDeactivateDate, creatorId) {
    const response = await api
      .get(`/api/v1/analytics/promotions/csv`, {
        responseType: 'blob',
        params: {
          dateFrom: dateFrom,
          dateTo: dateTo,
          raffleOnFrom: raffleOnFrom,
          raffleOnTo: raffleOnTo,
          deactivateDateFrom: deactivateDateFrom,
          deactivateDateTo: deactivateDateTo,
          expiresOnFrom: expiresOnFrom,
          expiresOnTo: expiresOnTo,
          marketingProgramId,
          type,
          partner,
          category,
          isActive,
          withoutDeactivateDate,
          creatorId
        }
      })
      .then(response => {
        return {status: response.status, data: response.data, request: response.request};
      });

    return response;
  }
  async function getPurchaseHistories(pagination, sorting, dateFrom, dateTo, marketingProgramId, searchByPromocodeString, promotionId, partnerId, promotionType, searchByEmailString, creatorId) {
    const response = await api
      .get(`/api/v1/purchase-histories`, {
        params: {
          searchByEmailString: searchByEmailString,
          searchByPromocodeString: searchByPromocodeString,
          ...pagination,
          ...sorting,
          dateFrom: dateFrom,
          dateTo: dateTo,
          marketingProgramId,
          promotionId,
          partnerId,
          promotionType,
          creatorId
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }
  async function getPurchaseHistoriesCsv({dateFrom, dateTo, marketingProgramId, searchByPromocodeString, promotionId, searchByEmailString, partnerId, promotionType, creatorId }) {
    const response = await api
      .get(`/api/v1/purchase-histories/csv`, {
        responseType: 'blob',
        params: {
          dateFrom: dateFrom,
          dateTo: dateTo,
          marketingProgramId,
          searchByEmailString: searchByEmailString,
          searchByPromocodeString: searchByPromocodeString,
          promotionId,
          partnerId,
          promotionType,
          creatorId
        }
      })
      .then(response => {
        return {status: response.status, data: response.data, request: response.request};
      });

    return response;
  }
  async function getPartners(pagination, sorting, search, creatorId) {
    const response = await api
      .get(`/api/v1/partners`, {
        params: {
          searchString: search,
          ...pagination,
          ...sorting,
          creatorId
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });
    return response;
  }

  async function getPartner(partnerId) {
    const response = await api
      .get(`/api/v1/partners/${partnerId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
  
    return response;
  }

  async function createPartner(partnerId, model) {
    const response = await api
      .post(`/api/v1/partners`, {partnerId, ...model, marketingProgramId})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function updatePartner(partnerId, model) {
    const response = await api
      .patch(`/api/v1/partners/${partnerId}`, model)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function uploadPartnerLogoDesktop(partnerId, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());

    const response = await api
        .patch(`/api/v1/partners/${partnerId}/logo-images/desktop`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });

    return response;
  }

  async function uploadPartnerLogoMobile(partnerId, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());

    const response = await api
        .patch(`/api/v1/partners/${partnerId}/logo-images/mobile`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });
    return response;
  }

  async function getCategories(pagination, sorting, search) {
    const response = await api
      .get(`/api/v1/categories`, {
        params: {
          searchString: search,
          ...pagination,
          ...sorting,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getCategory(categoryId) {
    const response = await api
      .get(`/api/v1/categories/${categoryId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
  
    return response;
  }

  async function createCategory(categoryId, model) {
    const response = await api
      .post(`/api/v1/categories`, {categoryId, ...model, marketingProgramId})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function updateCategory(categoryId, model) {
    const response = await api
      .patch(`/api/v1/categories/${categoryId}`, model)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }


  return {
    getPartners,
    getPartner,
    createPartner,
    updatePartner,
    uploadPartnerLogoDesktop,
    uploadPartnerLogoMobile,
    getCategories,
    getCategory,
    createCategory,
    updateCategory,
    getPurchaseHistories,
    getPurchaseHistoriesCsv,
    getPromotionHistories,
    getPromotionsTypes,
    getPromotionsPartners,
    getPromotionsCategories,
    getPurchasePromotionsCsv,
    generatePromotionAnalytics,
    getPromotionHistoriesReport,
    getPromotionHistoriesCsvReport,
    generatePurchaseHistory,
    getPurchaseHistoryReport,
    getPurchaseHistoryCsvReport
  };
}

export default {
  withMarketingProgram,
};
