<template>
    <div>
      <div class="mb-6">
        <h2 class="cmn__h1 mb-5">Аналитика за период</h2>
        <v-row>
          <v-col>
            <v-text-field
                label="Начало отчетного периода"
                v-model="analiticsParams.startDate"
                :rules="validation.date"
                hide-details="auto"
                append-outer-icon="mdi-calendar-blank"
                placeholder="ДД.ММ.ГГГГ"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                label="Конец отчетного периода"
                v-model="analiticsParams.endDate"
                :rules="validation.date"
                hide-details="auto"
                append-outer-icon="mdi-calendar-blank"
                placeholder="ДД.ММ.ГГГГ"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="d-flex align-end">
            <v-btn large @click="analiticsShow" class="ml-3" :disabled="analiticsCommonUpdating">Показать</v-btn>
            <v-btn large @click="analiticsCsv" :disabled="updateCSV" class="ml-3">
                <v-icon left>
                    mdi-content-save
                </v-icon>
                Скачать в CSV</v-btn>
          </v-col>
        </v-row>
        <v-row class="pa-4">
          <p v-if="updateCSV">Идет подготовка отчета...</p>
        </v-row>
        <v-row>
          <v-col>
            <DataTable v-if="analiticsTableShow"
              :key="analiticsCommonFiltersHash"
              :headers="analiticsCommonHeaders"
              :items="analiticsCommonItems"
              :loading="analiticsCommonUpdating"
              :hide-default-footer="true"
              @update:table-options="analiticsCommonTableUpdated"
            >
              <template v-slot:[`item.count`]="{item}">
                {{ item.count }}
                <span class="count-added" v-if="item.countAdded">({{ item.countAdded > 0 ? `+${item.countAdded}` : item.countAdded }})</span>
              </template>
            </DataTable>
          </v-col>
          <v-col>
            <DataTable v-if="analiticsTableShow"
              :key="analiticsFiltersHash"
              :headers="analiticsHeaders"
              :items="analiticsItems"
              :server-items-length="analiticsTotal"
              :loading="analiticsUpdating"
              @update:table-options="analiticsTableUpdated"
            >
              <template v-slot:[`item.count`]="{item}">
                {{ item.count }}
                <span class="count-added">({{ item.countAdded > 0 ? `+${item.countAdded}` : item.countAdded }})</span>
              </template>
            </DataTable>
          </v-col>
        </v-row>
      </div>
    </div>
  </template>
  
  <script>
  import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
  import scountApiInitializer from '@/api/scountApi';
  import ratingsApiInitializer from '@/api/ratingsApi';
  import Moment from 'moment'
  
  export default {
    name: 'AnaliticsView',
  
    setup() {
      const marketingProgramStore = useMarketingProgramStore()
      return { marketingProgramStore }
    },
  
    data: () => ({
      validation: {
        date: [
          value => !value || /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d$/i.test(value) || 'Некорректная дата. Формат ДД.ММ.ГГГГ',
        ],
      },
  
      analiticsCommonHeaders: [
        { value: 'name', text: 'Общая статистика', align: 'start', sortable: false },
        { value: 'count', text: '', align: 'start', sortable: false },
      ],
      analiticsCommonUpdating: false,
      analiticsCommonItems: undefined,
      analiticsCommonFiltersHash: Date.now(),
  
      analiticsHeaders: [
        { value: 'nominationName', text: 'Статистика по наминациям', align: 'start', sortable: false },
        { value: 'count', text: 'Всего участников', align: 'start', sortable: false },
      ],
      analiticsTotal: 0,
      analiticsUpdating: false,
      analiticsParams: {
        startDate: undefined,
        endDate: undefined,
      },
      analiticsItems: undefined,
      analiticsFiltersHash: Date.now(),
      analiticsTableShow: false,
      reportId: null,
      updateCSV: false,
      loadingData: false,
    }),

    computed: {
      mp() {
        if (this.marketingProgramStore.isInitialized === false)
          return null;
  
        return this.marketingProgramStore;
      },
  
      scountApi() {
        if (!this.mp)
          return null;
  
        return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },
  
      ratingsApi() {
        if (!this.mp)
          return null;
  
        return ratingsApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },
    },
  
    methods: {
      async checkReportStatus() {
        this.startTime = new Date();
        this.attempts = 0; // Инициализируем количество попыток
        const maxAttempts = 20; // Максимальное количество попыток
        const shortDelay = 5000; // 5 секунд в миллисекундах
        const longDelay = 30000; // 30 секунд в миллисекундах
        const maxDuration = 180000; // 3 минуты в миллисекундах

        while (this.attempts < maxAttempts) { // Проверяем количество попыток
            let delayTime; // Объявляем переменную для задержки

            try {
                let response;

                // Запрос на получение отчета
                if (!this.updateCSV) {
                    this.analiticsCommonUpdating = true;
                    response = await this.scountApi.getAnaliticsReport(this.reportId);
                    if (response.status === 200) {
                        this.analiticsCommonUpdating = false;
                        const d = response.data.common;
                        this.analiticsCommonItems = [
                            { name: 'Начисленные баллы', count: d.gainedPoints.count },
                            { name: 'Остаток по баллам', count: d.pointBalance.count, countAdded: d.pointBalance.countAdded },
                            { name: 'Сколько человек подключили трекер', count: d.countTrackerConnected.count },
                            { name: 'Сколько человек тренируется (фоновые+задания)', count: d.countTrainingsParticipants.count },
                            { name: 'Сколько человек берут только задания', count: d.countOnlyExercises.count },
                            { name: 'Количество женщин-участников', count: d.countFemale.count, countAdded: d.countFemale.countAdded },
                            { name: 'Количество мужчин-участников', count: d.countMale.count, countAdded: d.countMale.countAdded },
                        ];
                        console.log('Report is ready.');
                        this.isGeneratingReport = false;
                        break; // Выход из цикла, если статус 200
                    } else if (response.status === 404) {
                        console.log('Report not found, retrying...');
                        delayTime = this.attempts < 8 ? shortDelay : longDelay; // Задержка при 404
                    }
                } else {
                    response = await this.scountApi.getAnaliticsReportCsv(this.reportId);
                    const fileUrl = window.URL.createObjectURL(response.data);
                    const anchor = document.createElement("a");
                    anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
                    anchor.href = fileUrl;
                    anchor.target = "_blank";
                    anchor.click();
                    anchor.remove();
                    if (response.status === 200) {
                        this.updateCSV = false;
                        break;
                    } else if (response.status === 404) {
                        this.updateCSV = false;
                        delayTime = this.attempts < 8 ? shortDelay : longDelay; // Задержка при 404
                    }
                }
            } catch (error) {
                console.error('Error while checking report status:', error);
                delayTime = this.attempts < 8 ? shortDelay : longDelay; // Задержка при ошибке
            }

            this.attempts++;
            const elapsedTime = new Date() - this.startTime;

            // Проверяем, достигли ли максимального времени
            if (elapsedTime >= maxDuration) {
                this.isGeneratingReport = false;
                console.log('Max duration reached. Stopping checks.');
                break;
            }

            // Если задержка не была установлена, используем стандартное значение
            if (!delayTime) {
                delayTime = this.attempts < 8 ? shortDelay : longDelay; // Первые 8 попыток - 5 секунд, остальные - 30 секунд
            }
            
            await this.delay(delayTime); // Применяем задержку после каждой попытки
        }
    },

      // Функция задержки
      delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      async analiticsShow() {
        this.analiticsTableShow = true;
        this.analiticsFiltersHash = Date.now();
        this.analiticsCommonFiltersHash = Date.now();
      },
  
      async getAnalitics() {
        if (!this.scountApi) return;
        this.loadingData = true;
        this.activeCoreUpdating = true;
        try {
          await this.scountApi.generateAnalitics(
            this.analiticsParams.startDate ? Moment(this.analiticsParams.startDate, 'DD.MM.YYYY').toDate() : null,
            this.analiticsParams.endDate ? Moment(this.analiticsParams.endDate, 'DD.MM.YYYY').toDate() : null,
          )
          .then(response => {
            if (response.status === 200) {
                this.reportId = response.data;
                this.checkReportStatus();
            }
          });
        } finally {
          this.loadingData = false;
          this.activeCoreUpdating = false;
        }
      },
      async analiticsCommonTableUpdated(pagination, sorting, search) {
        this.getAnalitics(pagination, sorting, search);
      },
  
      async analiticsTableUpdated(pagination, sorting, search) {
        await this.getAnaliticsNominations(pagination, sorting, search);
      },
  
      async getAnaliticsNominations(pagination, sorting, search) {
        if (!this.ratingsApi) return;
  
        this.analiticsUpdating = true;
  
        try {
          const response = await this.ratingsApi.getAnaliticsByNominations(
            pagination,
            sorting,
            search,
            this.analiticsParams.startDate ? Moment(this.analiticsParams.startDate, 'DD.MM.YYYY').toDate() : null,
            this.analiticsParams.endDate ? Moment(this.analiticsParams.endDate, 'DD.MM.YYYY').toDate() : null,
          );
  
          const {values, total} = response.data;
  
          this.analiticsTotal = total;
          this.analiticsItems = values;
        } finally {
          this.analiticsUpdating = false;
        }
      },
      async analiticsCsv() {
        this.updateCSV = true;
        if (!this.scountApi) return;
        this.analiticsCommonUpdating = true;
        try {
          await this.scountApi.generateAnalitics(
            this.analiticsParams.startDate ? Moment(this.analiticsParams.startDate, 'DD.MM.YYYY').toDate() : null,
            this.analiticsParams.endDate ? Moment(this.analiticsParams.endDate, 'DD.MM.YYYY').toDate() : null,
          )
          .then(response => {
            if (response.status === 200) {
                this.reportId = response.data;
                this.checkReportStatus();
            }
          });
        } finally {
          this.analiticsCommonUpdating = false;
        }
      },
      // async analiticsCsv() {
      //   this.analiticsCsvPart1();
      //   this.analiticsCsvPart2();
      // },
  
      async analiticsCsvPart1() {
        const response = await this.scountApi.getAnaliticsCsv(
          this.analiticsParams.startDate ? Moment(this.analiticsParams.startDate, 'DD.MM.YYYY').toDate() : null,
          this.analiticsParams.endDate ? Moment(this.analiticsParams.endDate, 'DD.MM.YYYY').toDate() : null
        );
  
        const fileUrl = window.URL.createObjectURL(response.data);
  
        const anchor = document.createElement("a");
        anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
        anchor.href = fileUrl;
        anchor.target = "_blank";
        anchor.click();
  
        anchor.remove();
      },
  
      async analiticsCsvPart2() {
        const response = await this.ratingsApi.getAnaliticsByNominationsCsv(
          this.analiticsParams.startDate ? Moment(this.analiticsParams.startDate, 'DD.MM.YYYY').toDate() : null,
          this.analiticsParams.endDate ? Moment(this.analiticsParams.endDate, 'DD.MM.YYYY').toDate() : null
        );
  
        const fileUrl = window.URL.createObjectURL(response.data);
  
        const anchor = document.createElement("a");
        anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
        anchor.href = fileUrl;
        anchor.target = "_blank";
        anchor.click();
  
        anchor.remove();
      },
    }
  }
  </script>