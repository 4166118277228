<template>
    <div>
      <div class="mb-6">
        <h2 class="cmn__h1 mb-5">Аналитика по тренировкам</h2>
        <v-row>
          <v-col>
            <v-text-field
              label="Начало отчетного периода"
              v-model="ativityAnaliticsParams.startDate"
              :rules="validation.date"
              hide-details="auto"
              append-outer-icon="mdi-calendar-blank"
              placeholder="ДД.ММ.ГГГГ"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Конец отчетного периода"
              v-model="ativityAnaliticsParams.endDate"
              :rules="validation.date"
              hide-details="auto"
              append-outer-icon="mdi-calendar-blank"
              placeholder="ДД.ММ.ГГГГ"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="d-flex align-end">
            <v-btn large @click="ativityAnaliticsShow" class="ml-3">Показать</v-btn>
            <v-btn large @click="getActivityAnaliticsCsv" class="ml-3">
                <v-icon left>
                    mdi-content-save
                </v-icon>
                Скачать в CSV</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <DataTable v-if="ativityAnaliticsTableShow"
              :key="ativityAnaliticsFiltersHash"
              :headers="ativityAnaliticsExerciseHeaders"
              :items="ativityAnaliticsExerciseItems"
              :loading="ativityAnaliticsUpdating"
              :hide-default-footer="true"
              @update:table-options="ativityAnaliticsTableUpdated"
            >
              <template v-slot:[`item.count`]="{item}">
                {{ item.count }}
                <span class="count-added" v-if="item.countAdded">({{ item.countAdded > 0 ? `+${item.countAdded}` : item.countAdded }})</span>
              </template>
            </DataTable>
          </v-col>
          <v-col cols="6">
            <DataTable v-if="ativityAnaliticsTableShow"
              :headers="ativityAnaliticsActivityHeaders"
              :items="ativityAnaliticsActivityItems"
              :loading="ativityAnaliticsUpdating"
              :hide-default-footer="true"
            >
              <template v-slot:[`item.count`]="{item}">
                {{ item.count }}
                <span class="count-added" v-if="item.countAdded">({{ item.countAdded > 0 ? `+${item.countAdded}` : item.countAdded }})</span>
              </template>
            </DataTable>
          </v-col>
        </v-row>
      </div>
    </div>
  </template>
  
  <script>
  import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
  import scountApiInitializer from '@/api/scountApi';
  import ratingsApiInitializer from '@/api/ratingsApi';
  import Moment from 'moment'
  
  export default {
    name: 'AnaliticsView',
  
    setup() {
      const marketingProgramStore = useMarketingProgramStore()
      return { marketingProgramStore }
    },
  
    data: () => ({
      validation: {
        date: [
          value => !value || /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d$/i.test(value) || 'Некорректная дата. Формат ДД.ММ.ГГГГ',
        ],
      },
      ativityAnaliticsExerciseHeaders: [
        { value: 'name', text: 'Выполнено заданий', align: 'start', sortable: false },
        { value: 'count', text: '', align: 'start', sortable: false },
      ],
      ativityAnaliticsActivityHeaders: [
        { value: 'name', text: 'Принято тренировок', align: 'start', sortable: false },
        { value: 'count', text: '', align: 'start', sortable: false },
      ],
      ativityAnaliticsTotal: 0,
      ativityAnaliticsUpdating: false,
      ativityAnaliticsParams: {
        startDate: undefined,
        endDate: undefined,
      },
      ativityAnaliticsItems: undefined,
      ativityAnaliticsExerciseItems: undefined,
      ativityAnaliticsActivityItems: undefined,
      ativityAnaliticsFiltersHash: Date.now(),
      ativityAnaliticsTableShow: false,
      reportId: null,
    }),
  
    computed: {
      mp() {
        if (this.marketingProgramStore.isInitialized === false)
          return null;
  
        return this.marketingProgramStore;
      },
  
      scountApi() {
        if (!this.mp)
          return null;
  
        return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },
  
      ratingsApi() {
        if (!this.mp)
          return null;
  
        return ratingsApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },
    },

    methods: {
      async checkReportStatus() {
        this.startTime = new Date();
        this.attempts = 0; // Инициализируем количество попыток
        const maxAttempts = 20; // Максимальное количество попыток
        const shortDelay = 5000; // 5 секунд в миллисекундах
        const longDelay = 30000; // 30 секунд в миллисекундах
        const maxDuration = 180000; // 3 минуты в миллисекундах

        while (this.attempts < maxAttempts) { // Проверяем количество попыток
            let delayTime; // Объявляем переменную для задержки

            try {
                let response;

                // Запрос на получение отчета
                if (!this.updateCSV) {
                    this.analiticsCommonUpdating = true;
                    response = await this.scountApi.getActivityAnaliticsReport(this.reportId);
                    if (response.status === 200) {
                        this.analiticsCommonUpdating = false;
                        const d = response.data.common;
                this.ativityAnaliticsExerciseItems = [
                  { name: 'Тренировочных', count: d.exerciseSportActivity.count },
                  { name: 'Тестов', count: d.exerciseQuiz.count },
                  { name: 'Составных', count: d.exerciseComposite.count },
                  { name: 'Прочих заданий', count: d.exerciseOther.count },
                  { name: 'Итого', count: d.exerciseTotal.count },
                ];
                this.ativityAnaliticsActivityItems = [
                  { name: 'По ходьбе', count: d.activityWalking.count },
                  { name: 'По бегу', count: d.activityRunning.count },
                  { name: 'По плаванию', count: d.activitySwimming.count },
                  { name: 'По вело', count: d.activityCycling.count },
                  { name: 'По лыжам', count: d.activitySkiing.count },
                ];
                console.log('Report is ready.');
                this.isGeneratingReport = false;
                break; // Выход из цикла, если статус 200
                    } else if (response.status === 404) {
                        console.log('Report not found, retrying...');
                        delayTime = this.attempts < 8 ? shortDelay : longDelay; // Задержка при 404
                    }
                } else {
                    response = await this.scountApi.getActivityAnaliticsReportCsv(this.reportId);
                    const fileUrl = window.URL.createObjectURL(response.data);
                    const anchor = document.createElement("a");
                    anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
                    anchor.href = fileUrl;
                    anchor.target = "_blank";
                    anchor.click();
                    anchor.remove();
                    if (response.status === 200) {
                        this.updateCSV = false;
                        break;
                    } else if (response.status === 404) {
                        this.updateCSV = false;
                        delayTime = this.attempts < 8 ? shortDelay : longDelay; // Задержка при 404
                    }
                }
            } catch (error) {
                console.error('Error while checking report status:', error);
                delayTime = this.attempts < 8 ? shortDelay : longDelay; // Задержка при ошибке
            }

            this.attempts++;
            const elapsedTime = new Date() - this.startTime;

            // Проверяем, достигли ли максимального времени
            if (elapsedTime >= maxDuration) {
                this.isGeneratingReport = false;
                console.log('Max duration reached. Stopping checks.');
                break;
            }

            // Если задержка не была установлена, используем стандартное значение
            if (!delayTime) {
                delayTime = this.attempts < 8 ? shortDelay : longDelay; // Первые 8 попыток - 5 секунд, остальные - 30 секунд
            }
            
            await this.delay(delayTime); // Применяем задержку после каждой попытки
        }
    },
      delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      ativityAnaliticsShow() {
        this.ativityAnaliticsTableShow = true;
        this.ativityAnaliticsFiltersHash = Date.now();
      },

      async ativityAnaliticsTableUpdated(pagination, sorting, search) {
        await this.getActivityAnalitics(pagination, sorting, search);
      },

      async getActivityAnalitics() {
        if (!this.scountApi) return;
        this.ativityAnaliticsUpdating = true;
        try {
          await this.scountApi.generateActivityAnalitics(
            this.ativityAnaliticsParams.startDate ? Moment(this.ativityAnaliticsParams.startDate, 'DD.MM.YYYY').toDate() : null,
            this.ativityAnaliticsParams.endDate ? Moment(this.ativityAnaliticsParams.endDate, 'DD.MM.YYYY').toDate() : null,
          )
          .then(response => {
            if (response.status === 200) {
                this.reportId = response.data;
                this.checkReportStatus();
            }
          });
        } finally {
          this.ativityAnaliticsUpdating = false;
        }
      },
      async getActivityAnaliticsCsv() {
        this.updateCSV = true;
        if (!this.scountApi) return;
        this.ativityAnaliticsUpdating = true;
        try {
          await this.scountApi.generateActivityAnalitics(
            this.ativityAnaliticsParams.startDate ? Moment(this.ativityAnaliticsParams.startDate, 'DD.MM.YYYY').toDate() : null,
            this.ativityAnaliticsParams.endDate ? Moment(this.ativityAnaliticsParams.endDate, 'DD.MM.YYYY').toDate() : null,
          )
          .then(response => {
            if (response.status === 200) {
                this.reportId = response.data;
                this.checkReportStatus();
            }
          });
        } finally {
          this.ativityAnaliticsUpdating = false;
        }
      },

      // async getActivityAnalitics(pagination, sorting, search) {
      //   if (!this.scountApi) return;
      //   this.ativityAnaliticsUpdating = true;
      //   try {
      //     const response = await this.scountApi.getActivityAnalitics(
      //       pagination,
      //       sorting,
      //       search,
      //       this.ativityAnaliticsParams.startDate ? Moment(this.ativityAnaliticsParams.startDate, 'DD.MM.YYYY').toDate() : null,
      //       this.ativityAnaliticsParams.endDate ? Moment(this.ativityAnaliticsParams.endDate, 'DD.MM.YYYY').toDate() : null,
      //     );
      //     const d = response.data.common;
      //     this.ativityAnaliticsExerciseItems = [
      //       { name: 'Тренировочных', count: d.exerciseSportActivity.count },
      //       { name: 'Тестов', count: d.exerciseQuiz.count },
      //       { name: 'Составных', count: d.exerciseComposite.count },
      //       { name: 'Прочих заданий', count: d.exerciseOther.count },
      //       { name: 'Итого', count: d.exerciseTotal.count },
      //     ];
  
      //     this.ativityAnaliticsActivityItems = [
      //       { name: 'По ходьбе', count: d.activityWalking.count },
      //       { name: 'По бегу', count: d.activityRunning.count },
      //       { name: 'По плаванию', count: d.activitySwimming.count },
      //       { name: 'По вело', count: d.activityCycling.count },
      //       { name: 'По лыжам', count: d.activitySkiing.count },
      //     ];
      //   } finally {
      //     this.ativityAnaliticsUpdating = false;
      //   }
      // },
      // async ativityAnaliticsCsv() {
      //   const response = await this.scountApi.getAtivityAnaliticsCsv(
      //     this.ativityAnaliticsParams.startDate ? Moment(this.ativityAnaliticsParams.startDate, 'DD.MM.YYYY').toDate() : null,
      //     this.ativityAnaliticsParams.endDate ? Moment(this.ativityAnaliticsParams.endDate, 'DD.MM.YYYY').toDate() : null
      //   );
      //   const fileUrl = window.URL.createObjectURL(response.data);
      //   const anchor = document.createElement("a");
      //   anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
      //   anchor.href = fileUrl;
      //   anchor.target = "_blank";
      //   anchor.click();
      //   anchor.remove();
      // },
    }
  }
  </script>