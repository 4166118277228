<template>
    <div>
      <div class="mb-6">
        <h2 class="cmn__h1 mb-5">Отчет о покупках</h2>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Начало отчетного периода"
              v-model="newParticipantsParams.startDate"
              :rules="validation.date"
              hide-details="auto"
              append-outer-icon="mdi-calendar-blank"
              placeholder="ДД.ММ.ГГГГ"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Конец отчетного периода"
              v-model="newParticipantsParams.endDate"
              :rules="validation.date"
              hide-details="auto"
              append-outer-icon="mdi-calendar-blank"
              placeholder="ДД.ММ.ГГГГ"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              label="МП"
              :items="marketingPrograms2"
              item-text="title"
              item-value="marketingProgramId"
              v-model="marketingProgramId"
              no-data-text="Ничего не найдено"
              :clearable="marketingPrograms2 && marketingPrograms2.length !== 1"
              />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              label="Тип поощрения"
              :items="promotionTypeItems"
              item-text="title"
              item-value="value"
              v-model="promotionType"
              clearable
              />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="partner"
              label="Фильтр по партнеру"
              @click="partnersDialogOpen"
              readonly
              :append-icon="getClearIcon(partner)"
              @click:append="clearPartner"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="promotion"
              label="Фильтр по поощрению"
              @click="promotionDialogOpen"
              :append-icon="getClearIcon(promotion)"
              @click:append="clearPromotion"
              readonly
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="searchByPromocodeString"
                label="Поиск по значению промокода"
                clearable
              />
            </v-col>
          <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="searchByEmailString"
                label="Поиск по email участника"
                clearable
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" class="d-flex align-ceter">
              <v-btn large @click="newParticipantsShow">Показать</v-btn>
              <v-btn large @click="newParticipantsCsv" class="ml-3">
                <v-icon left>
                    mdi-content-save
                </v-icon>
                Скачать в CSV
              </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <DataTable
              v-if="newParticipantsTableShow"
              controller="inRoute"
              :key="newParticipantsFiltersHash"
              :headers="newParticipantsHeaders"
              :items="newParticipantsItems"
              :server-items-length="newParticipantsTotal"
              :loading="newParticipantsUpdating"
              @update:table-options="newParticipantsTableUpdated"
            >
              <template v-slot:[`item.participantName`]="{item}">
                <span class="trancate">{{ item.participantName ? item.participantName : '' }}</span>
              </template>
              <template v-slot:[`item.createdOn`]="{item}">
                {{ item.createdOn ? formatDate(item.createdOn) : '' }}
              </template>
              <template v-slot:[`item.tel`]="{item}">
                {{ item.attributes.find(attr => attr.name.value === "Tel") ? item.attributes.find(attr => attr.name.value === "Tel").value.value : '-' }}
              </template>
              <template v-slot:[`item.org`]="{item}">
                {{ item.attributes.find(attr => attr.name.value === "OrganizationName") ? item.attributes.find(attr => attr.name.value === "OrganizationName").value.value : '-' }}
              </template>
              <template v-slot:[`item.ticket`]="{item}">
                {{ item.attributes.find(attr => attr.name.value === "UnionMemberTicket") ? item.attributes.find(attr => attr.name.value === "UnionMemberTicket").value.value : '-' }}
              </template>
              <template v-slot:[`item.promotionCategory`]="{item}">
                {{ item.promotionCategory ? item.promotionCategory : '-' }}
              </template>
              <template v-slot:[`item.teamName`]="{item}">
                {{ item.team ? item.team.teamName : '-' }}
              </template>
              <template v-slot:[`item.address`]="{item}">
                {{ item.attributes.find(attr => attr.name.value === "HomeFull") ? item.attributes.find(attr => attr.name.value === "HomeFull").value.value : '-' }}
              </template>
              <template v-slot:[`item.promotionType`]="{item}">
                <div v-if="item.promotionType === 'Discount'">Скидка 100%</div>
                <div v-else-if="item.promotionType === 'Promocode'">Скидка &lt; 100%</div>
                <div v-else-if="item.promotionType === 'Prize'">Розыгрыш</div>
              </template>
            </DataTable>
          </v-col>
        </v-row>
      </div>
      <v-row v-if="newParticipantsItems">
        <v-col cols="6">
          <v-list-item>Транзакций на странице: <strong class="ml-2"> {{ transactionsOnPage }}</strong></v-list-item>
          <v-divider></v-divider>
          <v-list-item>Поощрений на странице: <strong class="ml-2"> {{ promotionsOnPage }}</strong></v-list-item>
          <v-divider></v-divider>
          <v-list-item>Участников на странице: <strong class="ml-2"> {{ participantsOnPage }}</strong></v-list-item>
          <v-divider></v-divider>
          <v-list-item>Балл(ов) потрачено на странице: <strong class="ml-2"> {{ totalBallsOnPage }}</strong></v-list-item>
          <v-divider></v-divider>
          <v-list-item>Всего транзакций: <strong class="ml-2"> {{ transactionsAll }}</strong></v-list-item>
          <v-divider></v-divider>
          <v-list-item>Всего поощрений: <strong class="ml-2"> {{ promotionsAll }}</strong></v-list-item>
          <v-divider></v-divider>
          <v-list-item>Всего участников: <strong class="ml-2"> {{ participantsAll }}</strong></v-list-item>
          <v-divider></v-divider>
          <v-list-item>Всего Балл(ов) потрачено: <strong class="ml-2"> {{ totalBallsAll }}</strong></v-list-item>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-dialog
        v-model="promotionDialog"
        max-width="800"
      >
      <v-card class="pa-4">
        <DataTable
          v-model="selected"
          :headers="headersPromotion"
          :items="promotionItems"
          :server-items-length="total"
          search-title="Найти поощрение"
          :search-fields="searchFieldsPromotion"
          sort-by="createdOn"
          :sort-desc="true"
          :loading="updatingPromotion"
          @update:table-options="updateTablePromotions"
          @click:row="clickPromotion"
          >
        </DataTable>
      </v-card>
    </v-dialog>
      <v-dialog
        v-model="partnersDialog"
        max-width="800"
      >
      <v-card class="pa-4">
        <DataTable
          v-model="selected"
          :headers="headersPartners"
          :items="partnersItems"
          :server-items-length="totalPartner"
          search-title="Найти партнеров"
          :search-fields="searchFieldsPromotion"
          :sort-desc="true"
          :loading="updatingPromotion"
          @update:table-options="updateTablePartners"
          @click:row="clickPartner"
          >
        </DataTable>
      </v-card>
    </v-dialog>
    </div>
  </template>

  <script>
  import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
  import { useKeycloakStore } from '@/stores/keycloakStore';
  import marketApiInitializer from '@/api/marketApi';
  import ratingsApiInitializer from '@/api/ratingsApi';
  import Moment from 'moment'
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: 'AnaliticsView',

    setup() {
      const marketingProgramStore = useMarketingProgramStore()
      const keycloakStore = useKeycloakStore()
      return { marketingProgramStore, keycloakStore }
    },

    data: () => ({
      validation: {
        date: [
          value => !value || /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d$/i.test(value) || 'Некорректная дата. Формат ДД.ММ.ГГГГ',
        ],
      },
      partnersDialog: false,
      partnerId: '',
      promotionDialog: false,
      promotionItems: [],
      partnersItems: [],
      promotion: '',
      partner: '',
      searchFieldsPromotion: [
        { key: 'name', label: 'Поощрение' },
      ],
      paginationPromotion: {
        page: 1,
        itemsPerPage: 10,
      },
      sortingPromotion: {
        sortBy: 'createdOn',
        sortDesc: true,
      },
      selected: [],
      promotionId: "",
      searchByEmailString: "",
      updatingPromotion: false,
      headersPromotion: [
        { value: 'name', text: '	Поощрение', align: 'start', sortable: true },
        { value: 'promocodeQty', text: 'Количество промокодов', align: 'center', sortable: false },
      ],
      headersPartners: [
        { value: 'name', text: '	Партнер', align: 'start', sortable: true },
      ],
      newParticipantsHeaders: [
        { value: 'participantEmail', text: 'Email Участника', align: 'start', sortable: false },
        { value: 'participantName', text: 'ФИО Участника', align: 'start', sortable: false, width: '200px' },
        { value: 'teamName', text: 'Команды', align: 'start', sortable: false },
        { value: 'address', text: 'Место жительства', align: 'start', sortable: false },
        { value: 'tel', text: 'Телефон', align: 'start', sortable: false },
        { value: 'org', text: 'Место работы', align: 'start', sortable: false },
        { value: 'ticket', text: 'Номер билета', align: 'start', sortable: false },
        { value: 'promotionName', text: 'Поощрение', align: 'start', sortable: false },
        { value: 'promotionCategory', text: 'Категория поощрения', align: 'start', sortable: false },
        { value: 'promotionType', text: 'Тип поощрения', align: 'start', sortable: false },
        { value: 'priceInPoints', text: 'Цена поощрения в баллах на момент покупки', align: 'center', sortable: false },
        { value: 'promocodeCode', text: 'Значение промокода', align: 'start', sortable: false },
        { value: 'promotionPartnerName', text: 'Партнер', align: 'start', sortable: false },
        { value: 'createdOn', text: 'Дата покупки', align: 'start', sortable: false },
        { value: 'marketingProgramName', text: 'МП', align: 'start', sortable: false },
      ],
      newParticipantsTotal: 0,
      newParticipantsUpdating: false,
      newParticipantsParams: {
        startDate: undefined,
        endDate: undefined,
      },
      newParticipantsItems: undefined,
      newParticipantsFiltersHash: Date.now(),
      newParticipantsTableShow: false,
      searchByPromocodeString: "",
      analiticData: [
        {title: "Транзакций на странице", value: ''},
        {title: "Поощрений на странице", value: ''},
        {title: "Участников на странице", value: ''},
        {title: "Балл(ов) потрачено на странице", value: ''},
        {title: "Всего транзакций", value: ''},
        {title: "Всего поощрений", value: ''},
        {title: "Всего участников", value: ''},
        {title: "Всего Балл(ов) потрачено", value: ''}
      ],
      promotionTypeItems: [
        {title:'Скидка 100%', value: 'Discount'},
        {title:'Скидка < 100%', value: 'Promocode'},
        {title:'Розыгрыш', value: 'Prize'}
      ],
      promotionType: '',
      transactionsOnPage: null,
      promotionsOnPage: null,
      participantsOnPage: null,
      totalBallsOnPage: null,
      transactionsAll: null,
      promotionsAll: null,
      participantsAll: null,
      totalBallsAll: null,
      searchFields: [
        { key: 'participantEmail', label: 'Email Участника' },
      ],
      marketingProgramId: '',
      reportId: null,
      updateCSV: false
    }),

    computed: {
      ...mapGetters("promotions", ["marketingPrograms", "promotions", "partners", "total", "totalPartner"]),
      mp() {
        if (this.marketingProgramStore.isInitialized === false)
          return null;

        return this.marketingProgramStore;
      },

      marketingPrograms2() {
        if (this.kl.isAnyMp) {
          return this.mp ? [ { marketingProgramId: this.mp.marketingProgramId, title: this.mp.title }] : [];
        } else {
          return this.marketingPrograms;
        }
      },

      marketApi() {
        if (!this.mp)
          return null;

        return marketApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },

      ratingsApi() {
        if (!this.mp)
          return null;

        return ratingsApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },

      kl() {
        return this.keycloakStore;
      },
    },

    methods: {
      ...mapActions("promotions", ["getMarketingPrograms", "getPromotions", "getPartners"]),
      async checkReportStatus() {
        this.startTime = new Date();
        this.attempts = 0; // Инициализируем количество попыток
        const maxAttempts = 50; // Максимальное количество попыток
        const shortDelay = 5000; // 5 секунд в миллисекундах
        const longDelay = 30000; // 30 секунд в миллисекундах
        const maxDuration = 180000; // 3 минуты в миллисекундах

        while (this.attempts < maxAttempts) { // Проверяем количество попыток
          let delayTime;
          try {
            let response;
            if (!this.updateCSV) {
              response = await this.marketApi.getPurchaseHistoryReport(this.reportId);
              if (response.status === 200) {
                const {values, total} = response.data.page;
                const { 
                  transactionsOnPage, 
                  promotionsOnPage, 
                  participantsOnPage, 
                  totalBallsOnPage, 
                  transactionsAll, 
                  promotionsAll,
                  participantsAll,
                  totalBallsAll 
                } = response.data
                this.newParticipantsTotal = total;
                this.newParticipantsItems = values;
                this.transactionsOnPage = transactionsOnPage;
                this.promotionsOnPage = promotionsOnPage;
                this.participantsOnPage = participantsOnPage;
                this.totalBallsOnPage = totalBallsOnPage;
                this.transactionsAll = transactionsAll;
                this.promotionsAll = promotionsAll;
                this.participantsAll = participantsAll;
                this.totalBallsAll = totalBallsAll
                  console.log('Report is ready.');
                  this.isGeneratingReport = false;
                  break; // Выход из цикла, если статус 200
              } else if (response.status === 404) {
                delayTime = this.attempts < 8 ? shortDelay : longDelay; // Задержка при 404
              }
            } else {
              response = await this.marketApi.getPurchaseHistoryCsvReport(this.reportId);
              const fileUrl = window.URL.createObjectURL(response.data);
              const anchor = document.createElement("a");
              anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
              anchor.href = fileUrl;
              anchor.target = "_blank";
              anchor.click();
              anchor.remove();
              if (response.status === 200) {
                this.updateCSV = false;
                break;
              } else if (response.status === 404) {
                this.updateCSV = false;
              }
            }
          } catch (error) {
            console.error('Error while checking report status:', error);
          }
          this.attempts++;
          const elapsedTime = new Date() - this.startTime;
          // Проверяем, достигли ли максимального времени
          if (elapsedTime >= maxDuration) {
            this.isGeneratingReport = false;
            console.log('Max duration reached. Stopping checks.');
            break;
          }
          delayTime = this.attempts < 8 ? shortDelay : longDelay; // Первые 8 попыток - 5 секунд, остальные - 30 секунд
          await this.delay(delayTime);
          // Проверка на максимальное количество попыток
          if (this.attempts >= maxAttempts) {
              this.isGeneratingReport = false;
              console.log('Max attempts reached. Stopping checks.');
              break;
          }
        }
      },
      // async checkReportStatus() {
      //   this.startTime = new Date();
      //   // eslint-disable-next-line no-constant-condition
      //   while (true) {
      //     try {
      //       let response;

      //       if (!this.updateCSV) {
      //         response = await this.marketApi.getPurchaseHistoryReport(this.reportId);
      //         if (response.status === 200) {
      //         const {values, total} = response.data.page;
      //         const { 
      //           transactionsOnPage, 
      //           promotionsOnPage, 
      //           participantsOnPage, 
      //           totalBallsOnPage, 
      //           transactionsAll, 
      //           promotionsAll,
      //           participantsAll,
      //           totalBallsAll 
      //         } = response.data
      //         this.newParticipantsTotal = total;
      //         this.newParticipantsItems = values;
      //         this.transactionsOnPage = transactionsOnPage;
      //         this.promotionsOnPage = promotionsOnPage;
      //         this.participantsOnPage = participantsOnPage;
      //         this.totalBallsOnPage = totalBallsOnPage;
      //         this.transactionsAll = transactionsAll;
      //         this.promotionsAll = promotionsAll;
      //         this.participantsAll = participantsAll;
      //         this.totalBallsAll = totalBallsAll
      //           console.log('Report is ready.');
      //           this.isGeneratingReport = false;
      //           break; // Выход из цикла, если статус 200
      //         } else if (response.status === 404) {
      //           console.log('Report not found, retrying...');
      //         }
      //       } else {
      //         response = await this.marketApi.getPurchaseHistoryCsvReport(this.reportId);
      //         const fileUrl = window.URL.createObjectURL(response.data);
      //         const anchor = document.createElement("a");
      //         anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
      //         anchor.href = fileUrl;
      //         anchor.target = "_blank";
      //         anchor.click();
      //         anchor.remove();
      //         if (response.status === 200) {
      //           this.updateCSV = false;
      //           break;
      //         } else if (response.status === 404) {
      //           this.updateCSV = false;
      //         }
      //       }
      //     } catch (error) {
      //       console.error('Error while checking report status:', error);
      //     }

      //     this.attempts++;
      //     const elapsedTime = new Date() - this.startTime;

      //     if (elapsedTime >= this.maxDuration) {
      //       console.log('Max duration reached. Stopping checks.');
      //       break;
      //     }

      //     const delayTime = this.attempts < this.maxAttempts ? this.intervalShort : this.intervalLong;
      //     await this.delay(delayTime);
      //   }
      // },
    // Функция задержки
      delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      newParticipantsShow() {
        this.newParticipantsTableShow = true;
        this.newParticipantsFiltersHash = Date.now();
      },
      clearPartner() {
        this.partner = '';
        this.partnerId = null;
      },
      clearPromotion() {
        this.promotion = '';
        this.promotionId = null;
      },
      getClearIcon(value) {
        return value ? 'mdi-close' : '';
      },
      formatDate(date) {
        return Moment(date).format('DD.MM.YYYY');
      },
      async newParticipantsTableUpdated(pagination, sorting, search) {
        await this.getNewParticipants(pagination, sorting, search);
      },
  
      async getNewParticipants(pagination, sorting) {
        if (!this.marketApi) return;
  
        this.newParticipantsUpdating = true;
  
        try {
          const response = await this.marketApi.generatePurchaseHistory(
            pagination,
            sorting,
            this.newParticipantsParams.startDate ? Moment(this.newParticipantsParams.startDate, 'DD.MM.YYYY').toDate() : null,
            this.newParticipantsParams.endDate ? Moment(this.newParticipantsParams.endDate, 'DD.MM.YYYY').toDate() : null,
            this.marketingProgramId,
            this.searchByPromocodeString,
            this.promotionId,
            this.partnerId,
            this.promotionType,
            this.searchByEmailString,
            this.kl.filterByCreatorId
          );
  

          this.reportId = response.data
          this.checkReportStatus();
        } finally {
          this.newParticipantsUpdating = false;
        }
      },
  
      async newParticipantsCsv(sorting) {
        const pagination = { offset: 0, count: 100000 };
        this.updateCSV = true;
        await this.marketApi.generatePurchaseHistory(
          pagination,
          sorting,
          this.newParticipantsParams.startDate ? Moment(this.newParticipantsParams.startDate, 'DD.MM.YYYY').toDate() : null,
          this.newParticipantsParams.endDate ? Moment(this.newParticipantsParams.endDate, 'DD.MM.YYYY').toDate() : null,
          this.marketingProgramId,
            this.searchByPromocodeString,
            this.promotionId,
            this.partnerId,
            this.promotionType,
            this.searchByEmailString,
            this.kl.filterByCreatorId
          ).then(response => {
          if (response.status === 200) {
            this.reportId = response.data;
            this.checkReportStatus();
          }
        });
      },
  
        // const fileUrl = window.URL.createObjectURL(response.data);
  
        // const anchor = document.createElement("a");
        // anchor.download = decodeURI(response.request.getResponseHeader("X-Data-FileName")).replace(/\+/g, " ");
        // anchor.href = fileUrl;
        // anchor.target = "_blank";
        // anchor.click();
  
        // anchor.remove();
      promotionDialogOpen() {
        this.promotionDialog = true;
      },
      partnersDialogOpen() {
        this.partnersDialog = true;
      },
      updateTablePromotions(pagination, sorting, search) {
        this.getPromotions({ pagination, sorting, search, creatorId: this.kl.filterByCreatorId })
        .then(() => {
          this.promotionItems = this.promotions.values
          this.updatingPrmotions = false;
        });
      },
      updateTablePartners(pagination, sorting, search) {
        this.getPartners({ pagination, sorting, search, creatorId: this.kl.filterByCreatorId })
        .then(() => {
          this.partnersItems = this.partners
          this.updatingPrmotions = false;
        });
      },
      clickPromotion(e) {
        this.promotionId = e.promotionId
        this.promotionDialog = false
        this.promotion = e.name
      },
      clickPartner(e) {
        this.partnerId = e.partnerId
        this.partnersDialog = false
        this.partner = e.name
      },
    },
    async mounted() {
      if (this.kl.isAnyMp)
        this.marketingProgramId = this.mp.marketingProgramId;
      else
        this.getMarketingPrograms();
    }
  }
  </script>